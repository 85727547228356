/**
 * Descripcion del helper: Este helper se encarga de eliminar los keys que estén vacíos dentro de un objeto, detecta los nulls o los false.
 *
 * Implementacion: Se importa y se pasa como parametro el objeto.
 */

const DeleteEmptyKeys = (originalObj, search) => {
  const params = new URLSearchParams(search && { action: 'search' });

  Object.entries(originalObj).forEach(([key, value]) => {
    if (Array.isArray(value) && value.length > 0) {
      // Join array values into a comma-separated string
      params.append(key, value.join(','));
    } else if (value && value.length > 0) {
      // Append non-empty strings or other non-array values
      params.append(key, value);
    }
  });

  return params;
};

export default DeleteEmptyKeys;

import React, { useEffect, useState } from 'react';
import ReactSelect from 'react-select';
import PropTypes from 'prop-types';

// Descripción: Este componente se encarde contener los charts y ajustar en base a la conf que se reciba

const Graphicscontainers = ({
  data,
  size,
  filters,
  children,
  isSuccess,
  isLoading,
  setFilters,
  hasFilters,
  footerAlign,
  headerTitle,
  // indicators = defaultIndicators,
  indicators,
}) => {
  const [fromOptions, setFromOptions] = useState([]);
  const [toOptions, setToOptions] = useState([]);

  const [fromSelected, setFromSelected] = useState({
    value: filters?.from || '',
    label: 'From',
  });
  const [toSelected, setToSelected] = useState({
    value: filters?.to || '',
    label: 'To',
  });

  const handleChange = ({ from, to }) => {
    if (from) {
      setToSelected({ value: '', label: 'To' });
      setFromSelected(from);
      setFilters({ ...filters, from: from.value });
    }

    if (to) {
      setToSelected(to);
      setFilters({ ...filters, from: fromSelected.value, to: to.value });
    }
  };

  useEffect(() => {
    if (isSuccess && !isLoading) {
      const period = data?.data?.specificPeriod;

      if (period && fromSelected.value === '') {
        setFromOptions([]);

        period.all_options.forEach((item) => {
          setFromOptions((fromOptions) => [
            ...fromOptions,
            { value: item, label: item.toUpperCase() },
          ]);
        });
      }

      if (period && fromSelected.value !== '') {
        setToOptions([]);

        period.period_options.forEach((item) => {
          setToOptions((toOptions) => [
            ...toOptions,
            { value: item, label: item.toUpperCase() },
          ]);
        });
      }
    }
  }, [fromSelected, data, isSuccess, isLoading]);

  // Renders
  return (
    <>
      <div className='analytics__graphics' style={{ gridColumn: size }}>
        <div className='analytics__graphics__header'>
          <p>{headerTitle}</p>
        </div>

        {hasFilters && (
          <div className='analytics__graphics__filters'>
            <ReactSelect
              id='from'
              name='from'
              isSearchable
              value={fromSelected}
              isLoading={isLoading}
              options={fromOptions}
              className='analytics__selects analytics__short-filter'
              loadingMessage='Loading...'
              classNamePrefix='analytics__selects'
              onChange={(value) => handleChange({ from: value })}
            />

            <ReactSelect
              id='to'
              name='to'
              options={toOptions}
              isSearchable
              className='analytics__selects analytics__short-filter'
              loadingMessage='Loading...'
              classNamePrefix='analytics__selects'
              isLoading={isLoading}
              value={toSelected}
              onChange={(value) => handleChange({ to: value })}
            />
          </div>
        )}

        <div className='analytics__graphics__container'>{children}</div>

        <div
          className='analytics__graphics__footer'
          style={{ justifyContent: footerAlign }}
        >
          {indicators &&
            indicators.map((indicator, index) => (
              <p key={index}>
                <span style={{ backgroundColor: [indicator.color] }} />{' '}
                {indicator.title}
              </p>
            ))}
        </div>
      </div>
    </>
  );
};

Graphicscontainers.propTypes = {
  // Determina si se tienen que mostrar los filtros
  hasFilters: PropTypes.bool,
  // size debe ser una cadena de texto
  size: PropTypes.string,
  // children debe ser un elemento y es obligatorio (isRequired)
  children: PropTypes.element.isRequired,
  // footerAlign debe ser una de estas tres opciones: 'flex-start', 'center' o 'flex-end'
  footerAlign: PropTypes.oneOf(['flex-start', 'center', 'flex-end']),
  // headerTitle debe ser una cadena de texto y es obligatorio (isRequired)
  headerTitle: PropTypes.string.isRequired,

  // indicatorTitle debe ser una cadena de texto o string
  indicators: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        color: PropTypes.string,
      })
    ),
  ]),
};

Graphicscontainers.defaultProps = {
  // Como valor inicial es falso
  hasFilters: false,
  // size es nulo por defecto
  size: '',
  // footerAlign es 'flex-start' por defecto
  footerAlign: 'flex-start',
  // indicators: defaultIndicators,
  indicators: [],
};

export default Graphicscontainers;

import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import Lottie from 'lottie-react';
import GraphicsContext from '../../contexts/GraphicsContext';
import { useGraphicsPerCountry } from '../../hooks/useGraphics';
import ModalButtons from '../Buttons/ModalButton';
import ModalActions from '../Buttons/ModalActions';
import SeparatorNumber from '../../helpers/SeparatorNumber';
import ResumeNumbers from '../../helpers/ResumeNumbers';
import ModalsCardsInfo from '../Cards/ModalsCardsInfo';
import LottieLoader from '../../assets/animations/cyber_loader.json';
import LottieError from '../../assets/animations/error_lottie.json';
import Colors from '../../assets/scss/variables/colors.module.scss';

// Descripción: Este componente renderiza la info del pais seleccionado.

const ModalCountry = ({
  show,
  zones,
  country,
  setShow,
  setFullView,
  setGraphicsScreen,
}) => {
  // Estado

  // Contextos
  const { countryInfo, setCountryInfo } = useContext(GraphicsContext);

  // Hooks
  const { data, isLoading, isSuccess, isError, filters, setFilters } =
    useGraphicsPerCountry();

  // Funciones
  const {
    colorPrimario,
    colorSecundario,
    colorVariante,
    colorVarianteSeis,
    colorUltra,
  } = Colors;

  const handleClickGrahics = () => {
    setShow(!show);
    setGraphicsScreen(true);
    setCountryInfo({
      ...countryInfo,
      country,
      zone: zones,
      chart: data.data,
    });
  };

  const handleClick = () => {
    setFullView(true);
  };

  useEffect(() => {
    if (country) {
      setFilters({ ...filters, country: country.name });
    }
  }, [country]);

  if (country === 0) {
    return null;
  }

  return (
    <>
      {isLoading && (
        <div className='modalcountry'>
          <div className={`modalcountry__container ${zones}`}>
            {/* Content */}

            <Lottie
              style={{ width: '40%', margin: '0 auto' }}
              animationData={LottieLoader}
            />
            <h1 style={{ margin: '0 auto' }}>Searching...</h1>
          </div>
        </div>
      )}

      {isSuccess && (
        <div className='modalcountry'>
          <div className={`modalcountry__container ${zones}`}>
            {/* Content */}
            <h1>{country?.name || 'No data'}</h1>

            <div className='modalcountry__grid'>
              {/* Evo zones */}
              {country?.zone.includes('evo') &&
              (zones === 'evo' || zones === 'all') ? (
                <ModalsCardsInfo
                  color={zones === 'evo' ? colorSecundario : colorPrimario}
                  label='Intel® Evo™ Zones'
                  number={SeparatorNumber(data.data.implementation.evoTotal)}
                />
              ) : null}

              {/* Gaming */}
              {country?.zone.includes('gaming') &&
              (zones === 'gaming' || zones === 'all') ? (
                <ModalsCardsInfo
                  label='Gaming Zones'
                  color={zones === 'gaming' ? colorVariante : colorPrimario}
                  number={SeparatorNumber(data.data.implementation.gamingTotal)}
                />
              ) : null}

              {/* Unison Riser */}
              {country?.zone.includes('unison riser') &&
              (zones === 'unison riser' || zones === 'all') ? (
                <ModalsCardsInfo
                  label='Intel® Unison™'
                  color={
                    zones === 'unison riser' ? colorVarianteSeis : colorPrimario
                  }
                  number={SeparatorNumber(data.data.implementation.unisonTotal)}
                />
              ) : null}

              {/* Ultra */}
              {country?.zone.includes('ultra') &&
              (zones === 'ultra' || zones === 'all') ? (
                <ModalsCardsInfo
                  label='Intel® Core™ Ultra'
                  color={zones === 'ultra' ? colorUltra : colorPrimario}
                  number={SeparatorNumber(data.data.implementation.ultraTotal)}
                />
              ) : null}

              {/* Stores */}
              <ModalsCardsInfo
                color={
                  zones === 'gaming'
                    ? colorVariante
                    : zones === 'evo'
                    ? colorSecundario
                    : zones === 'unison riser'
                    ? colorVarianteSeis
                    : zones === 'ultra'
                    ? colorUltra
                    : colorPrimario
                }
                label='Stores'
                number={SeparatorNumber(data.data.implementation.total)}
              />

              <div className='modalcountry__daily'>
                <div className='modalcountry__spark' />

                <div className='modalcountry__tooltip'>
                  <p>
                    {SeparatorNumber(
                      data.data.implementation.totalImpresions?.total
                    )}
                  </p>
                  <div className='modalcountry__tooltip__icon' />
                </div>

                <h2>
                  {ResumeNumbers(
                    data.data.implementation.totalImpresions?.total
                  ) || '0'}
                </h2>
                <span>Daily impressions</span>
              </div>
            </div>
          </div>

          <div className='modalcountry__button'>
            <ModalButtons type={zones} onClick={handleClickGrahics} />
            <ModalActions
              type={zones}
              title='See Experience Zones'
              onClick={handleClick}
            />
          </div>
        </div>
      )}

      {isError && (
        <div className='modalcountry'>
          <div className={`modalcountry__container ${zones}`}>
            {/* Content */}

            <Lottie
              style={{ width: '40%', margin: '0 auto' }}
              animationData={LottieError}
            />
            <h1 style={{ margin: '0 auto' }}>Something has gone wrong.</h1>
          </div>
        </div>
      )}
    </>
  );
};

ModalCountry.propTypes = {
  zones: PropTypes.string,
  setFullView: PropTypes.func.isRequired,
};

ModalCountry.defaultProps = {
  zones: 'all',
};

export default ModalCountry;

import React from 'react';
import Lottie from 'lottie-react';
import LottieLoader from '../../assets/animations/cyber_loader.json';

// Descripción: La descripción de por qué estamos haciendo este componente y que resuelve.

const DetailsbyAccount = ({ data, isError, isLoading, isSuccess }) => {
  return (
    <>
      <table>
        <thead>
          <tr>
            <th>GEO</th>
            <th>Country</th>
            <th>Account</th>
            <th>Evo Zones</th>
            <th>Gaming Zones</th>
            <th>Core Ultra Zones</th>
            <th>Unison</th>
            {/* <th>Zone Coverage</th> */}
          </tr>
        </thead>

        <tbody>
          {isLoading && (
            <>
              <tr>
                <td colSpan={7}>
                  <Lottie
                    style={{ width: '17%', margin: '0 auto' }}
                    animationData={LottieLoader}
                  />
                </td>
              </tr>
              {/* <tr>
                <td>Loading...</td>

                <td>-</td>

                <td>-</td>

                <td>-</td>

                <td>-</td>

                <td>-</td>
              </tr> */}
            </>
          )}

          {isSuccess && (
            <>
              {data.results?.map((item, index) => (
                <tr key={index}>
                  <td>{item.geo}</td>

                  <td>{item.country}</td>

                  <td>{item.account}</td>

                  <td align='justify'>
                    <span>{item.evo || '-'}</span>
                  </td>

                  <td>
                    <span>{item.gaming || '-'}</span>
                  </td>
                  <td>
                    <span>{item.ultra || '-'}</span>
                  </td>
                  <td>
                    <span>{item['unison riser'] || '-'}</span>
                  </td>
                  {/* <td>{`${item.coverage}%` || '-'}</td> */}
                </tr>
              ))}

              {!data.results?.length && (
                <tr>
                  <td>No data...</td>

                  <td>-</td>

                  <td>-</td>

                  <td align='justify'>
                    <span>-</span>
                  </td>

                  <td>
                    <span>-</span>
                  </td>

                  <td>-</td>
                </tr>
              )}
            </>
          )}

          {isError && (
            <tr>
              <td>Something has gone wrong...</td>

              <td>-</td>

              <td>-</td>

              <td>-</td>

              <td>-</td>

              <td>-</td>
            </tr>
          )}
        </tbody>
        <tfoot>
          {isLoading && (
            <tr>
              <td>TOTALS</td>

              <td />

              <td />

              <td />

              <td />

              <td />

              <td />
            </tr>
          )}

          {isSuccess && (
            <>
              <tr>
                <td>TOTALS</td>

                <td />

                <td />

                <td align='justify'>
                  <span>{data.info?.detailsByAccount.evo || 0}</span>
                </td>

                <td>
                  <span>{data.info?.detailsByAccount.gaming || 0}</span>
                </td>
                <td>
                  <span>{data.info?.detailsByAccount.ultra || 0}</span>
                </td>

                <td>
                  <span>
                    {data.info?.detailsByAccount['unison riser'] || 0}
                  </span>
                </td>
              </tr>
            </>
          )}

          {isError && (
            <tr>
              <td>Something has gone wrong...</td>

              <td />

              <td />

              <td />

              <td />

              <td />
            </tr>
          )}
        </tfoot>
      </table>
    </>
  );
};

DetailsbyAccount.propTypes = {};

DetailsbyAccount.defaultProps = {};

export default DetailsbyAccount;

import { useState } from 'react';
import { useQuery } from 'react-query';
import api from '../services/api.service';
import { RETAILERS_KEY } from '../constants/api.type';
import DeleteEmptyKeys from '../helpers/DeleteEmptyKeys';

// Descripcion del hook: Este hook se encarga de hacer el llamado a la api

export const useRetailersPerCountry = () => {
  const [filters, setFilters] = useState({
    country: null,
    keyword: null,
  });

  const { data, isLoading, isSuccess, isError } = useQuery(
    [RETAILERS_KEY, JSON.stringify(filters)],
    async () => {
      const filteredFilters = DeleteEmptyKeys(filters, true);

      return api.retailers.getRetailersByCountry(filteredFilters);
    }
  );

  return { data, isLoading, isSuccess, isError, filters, setFilters };
};

const useRetailers = () => {
  const retailers = useQuery([RETAILERS_KEY], async () =>
    api.countries.getAll()
  );

  return retailers;
};

export default useRetailers;

import React, { createContext, useState } from 'react';

/**
 * Descripcion del context: Este contexto se encarga de recibir el filtro de las zonas iniciales
 * (Zonas iniciales -> See all, Gaming, Evo)
 *
 * Implementacion: Se implementa como un contexto normal y por values se seta el estado.
 */

const FiltersZones = createContext(); // Crea un objeto

// - children es la referencia a los componentes hijos directos e indirectos
export const FiltersZonesProvier = ({ children }) => {
  const [zones, setZones] = useState('all');

  return (
    <FiltersZones.Provider value={{ zones, setZones }}>
      {children}
    </FiltersZones.Provider>
  );
};
export default FiltersZones;

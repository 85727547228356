import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import AsideActions from './components/AsideActions';
import AsideContainers from './components/AsideContainers';

const Aside = ({
  country,
  zones,
  show,
  setShow,
  fullView,
  setZones,
  setCountry,
  setFullView,
  expandAside,
  countryFocus,
  setExpandAside,
  setGraphicsScreen,
  setShowHelpScreen,
}) => {
  const [showFilter, setShowFilter] = useState('home');
  const location = useLocation();

  const handleClick = (pantallaActiva) => {
    setFullView(false);
    setGraphicsScreen(false);

    if (pantallaActiva === 'home') {
      setShow(false);
      setShowFilter('home');
      setZones('all');
    }

    if (pantallaActiva === 'search') {
      show !== true && setShow(!show);
      setShowFilter('search');
      setShow(true);
      setCountry(null);
      setFullView(false);
    }

    if (pantallaActiva === 'all') {
      show !== true && setShow(!show);
      setShowFilter('all');
    }

    if (pantallaActiva === 'evo') {
      show !== true && setShow(!show);
      setShowFilter('evo');
    }

    if (pantallaActiva === 'gaming') {
      show !== true && setShow(!show);
      setShowFilter('gaming');
    }

    if (pantallaActiva === 'unison riser') {
      show !== true && setShow(!show);
      setShowFilter('unison riser');
    }

    if (pantallaActiva === 'ultra') {
      show !== true && setShow(!show);
      setShowFilter('ultra');
    }

    // if (panel === 'menu') {
    //   setShow(!show);
    //   setShowFilter('menu');
    // }
  };

  const handleFilters = (zone) => {
    setShow(true);
    setZones(zone);
    setCountry(null);
    setFullView(false);
    handleClick(zone);
  };

  useEffect(() => {
    setCountry(null);
    setFullView(false);
    handleClick('home');
  }, [location]);

  useEffect(() => {
    if (
      country !== null &&
      country !== 0 &&
      (showFilter === 'home' || showFilter === 'search')
    ) {
      setShowFilter('all');
    }
  }, [country, countryFocus, setShowFilter]);

  return (
    // Aside multi nivel
    <aside
      className={`aside ${show ? 'isVisible' : ''} ${
        expandAside ? 'isExpand' : ''
      }`}
    >
      {/* Botones del lado izquierdo */}
      <AsideActions
        zones={zones}
        country={country}
        showFilter={showFilter}
        expandAside={expandAside}
        handleClick={handleClick}
        setFullView={setFullView}
        setShowFilter={setShowFilter}
        handleFilters={handleFilters}
        setExpandAside={setExpandAside}
        setShowHelpScreen={setShowHelpScreen}
      />

      {/* Panels */}
      <AsideContainers
        zones={zones}
        show={show}
        country={country}
        setShow={setShow}
        setZones={setZones}
        fullView={fullView}
        showFilter={showFilter}
        setCountry={setCountry}
        setFullView={setFullView}
        countryFocus={countryFocus}
        setShowFilter={setShowFilter}
        setGraphicsScreen={setGraphicsScreen}
      />
    </aside>
  );
};

export default Aside;

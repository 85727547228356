import React from 'react';

// eslint-disable-next-line import/prefer-default-export
export const AsideArrow = (props) => {
  return (
    <svg
      width='10'
      height='10'
      {...props}
      viewBox='0 0 10 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='m0 12 6.18-6L0 0h3.927L10 6l-6.073 6H0z' fill='currentColor' />
    </svg>
  );
};

/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import Lottie from 'lottie-react';
import { Label } from 'recharts';
import useAnalyticsByGeo from '../../../hooks/useAnalytics';
import LottieLoader from '../../../assets/animations/cyber_loader.json';
import { ZoneType } from './ZoneType';
import { OtherType } from './OtherType';
import LottieError from '../../../assets/animations/error_lottie.json';
import Colors from '../../../assets/scss/variables/colors.module.scss';

// eslint-disable-next-line import/prefer-default-export
export const OverviewGeo = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const { data, isLoading, isSuccess, isError } = useAnalyticsByGeo();

  const getGap = (screenWidth, total) => {
    if (screenWidth <= 950) return 120;
    if (screenWidth <= 1200) return 80;
    return Math.max(40, Math.min(15, (total / 500) * 40));
  };

  const dataFormatted = data?.map((region) => {
    return {
      ...region,
      evoGap: region.evo > 0 ? getGap(screenWidth, region.total) : 0,
      gamingGap: region.gaming > 0 ? getGap(screenWidth, region.total) : 0,
      ultraGap: region.ultra > 0 ? getGap(screenWidth, region.total) : 0,
    };
  });

  // valores de las barras
  const renderCustomizedLabel = (props) => {
    const { content, ...rest } = props;
    if (props.value !== 0)
      return <Label {...rest} fontSize='10' fill={Colors.colorClaro} />;
  };

  const renderCustomizedLegend = (props) => {
    const { payload } = props;
    const customLegend = payload.map((entry) => {
      let displayName = entry.value;
      switch (entry.value) {
        case 'evo':
          displayName = 'Intel® Evo™ Zones';
          break;
        case 'gaming':
          displayName = 'Gaming Zones';
          break;
        case 'ultra':
          displayName = 'Intel® Core™ Ultra Zones';
          break;
        case 'unison riser':
          displayName = 'Intel® Unison™';
          break;
        default:
          break;
      }
      return { ...entry, value: displayName };
    });

    return (
      <>
        <div
          className=''
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '10px',
            alignItems: 'center',
            justifyContent: 'start',
          }}
        >
          {customLegend.map((entry, index) => {
            if (
              entry.value === 'evoGap' ||
              entry.value === 'gamingGap' ||
              entry.value === 'ultraGap'
            ) {
              return;
            }
            return (
              <div
                key={`item-${index}`}
                style={{
                  color: Colors.colorClaro,
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  padding: '10px 0 48px',
                  fontSize: '11px',
                }}
              >
                <div
                  style={{
                    margin: '0 5px',
                    width: '10px',
                    height: '10px',
                    backgroundColor: entry.color,
                  }}
                />
                <span>{entry.value}</span>
              </div>
            );
          })}
        </div>
        <div>
          <p
            style={{
              position: 'absolute',
              top: '40px',
              left: '5px',
              color: Colors.colorClaro,
            }}
          >
            Zone Type
          </p>
          <p
            style={{
              position: 'absolute',
              top: '40px',
              right: '-28px',
              color: Colors.colorClaro,
            }}
          >
            Total
          </p>
        </div>
      </>
    );
  };

  return (
    <>
      {isLoading && (
        <Lottie
          style={{ width: '20%', margin: '0 auto' }}
          animationData={LottieLoader}
        />
      )}
      {isError && (
        <Lottie
          style={{ width: '10%', margin: '0 auto' }}
          animationData={LottieError}
        />
      )}

      {isSuccess && (
        <div className='analytics__table__geo-container'>
          <ZoneType
            dataFormatted={dataFormatted}
            renderCustomizedLabel={renderCustomizedLabel}
            renderCustomizedLegend={renderCustomizedLegend}
          />
          {/* SEPARATOR LINE  */}
          <div
            style={{
              width: '1px',
              backgroundColor: Colors.colorClaro,
              opacity: '0.4',
              height: '220px',
              margin: '0 0 0 15px',
            }}
          />
          <OtherType
            data={data}
            renderCustomizedLabel={renderCustomizedLabel}
          />
        </div>
      )}
    </>
  );
};

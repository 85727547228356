import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';

import { FiltersZonesProvier } from '../contexts/FiltersZonesContext';
import { CountrySelectedProvider } from '../contexts/CountrySelectedContext';
import { GrapihcsProvider } from '../contexts/GraphicsContext';

import Routes from './Routes';

function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false, // default: true
      },
    },
  });

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <FiltersZonesProvier>
          <CountrySelectedProvider>
            <GrapihcsProvider>
              <Routes />
            </GrapihcsProvider>
          </CountrySelectedProvider>
        </FiltersZonesProvier>
      </QueryClientProvider>
    </>
  );
}

export default App;

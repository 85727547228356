import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import PropTypes from 'prop-types';
import { AsideArrow } from '../assets/images/icons/asidearrow';

// Descripción: Este componente se representa como la paginacion en la partde superior de los filtros

const Paginate = ({ info, itemsPerPage, filters, setFilters }) => {
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(info.page ? info.page - 1 : 0);

  const handlePageChange = (selectedObject) => {
    setCurrentPage(selectedObject.selected);
    setFilters({ ...filters, page: `${selectedObject.selected + 1}` });
  };

  useEffect(() => {
    setPageCount(Math.ceil(info.totalResults / itemsPerPage));
  }, [info, itemsPerPage]);

  useEffect(() => {
    // Reset currentPage when filters.page changes
    if (filters.page) {
      setCurrentPage(filters.page - 1);
    }
  }, [filters.page]);

  return (
    <>
      {info.totalResults !== 0 && (
        <ReactPaginate
          nextLabel={<AsideArrow />}
          breakLabel='...'
          previousLabel={
            currentPage === 0 ? (
              ''
            ) : (
              <AsideArrow style={{ transform: 'rotate(180deg)' }} />
            )
          }
          pageCount={pageCount}
          disableInitialCallback
          pageRangeDisplayed={2}
          forcePage={currentPage}
          renderOnZeroPageCount={null}
          onPageChange={handlePageChange}
        />
      )}
    </>
  );
};

Paginate.propTypes = {
  info: PropTypes.object,
  itemsPerPage: PropTypes.number,
  filters: PropTypes.object,
  setFilters: PropTypes.func,
};

Paginate.defaultProps = {
  info: {
    totalResults: 50,
  },
  itemsPerPage: 10,
  filters: {},
  setFilters: () => null,
};

export default Paginate;

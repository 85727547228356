import React from 'react';
import SeparatorNumber from '../../helpers/SeparatorNumber';

/**
 * Descripción: Tooltip customizado para el chart
 *
 * Implementación: Se usa dentro de <Tooltip content={<TooltipChartModals />} />
 */

const TooltipChartModals = ({ active, payload, simbol }) => {
  if (active && payload && payload.length) {
    return (
      <div className='tooltipchart'>
        {payload.map(({ color, value }, index) => {
          return (
            <div key={index} className='tooltipchart__content'>
              <p>
                <span
                  className='spark'
                  style={{ marginRight: 5, backgroundColor: color }}
                />
                {`${SeparatorNumber(value)} ${simbol || ''} `}
              </p>
            </div>
          );
        })}

        <div className='tooltipchart__arrow' />
      </div>
    );
  }

  return null;
};

TooltipChartModals.propTypes = {};

TooltipChartModals.defaultProps = {};

export default TooltipChartModals;

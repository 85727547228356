import React, { useEffect, useState } from 'react';
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  ResponsiveContainer,
  Legend,
  Text,
} from 'recharts';
import Lottie from 'lottie-react';
import { useZonesImplementations } from '../../hooks/useAnalytics';
import Graphicscontainers from './Graphicscontainers';
import TooltipChart from '../Modals/TooltipChartPie';
import LottieLoader from '../../assets/animations/cyber_loader.json';
import LottieError from '../../assets/animations/error_lottie.json';
import Colors from '../../assets/scss/variables/colors.module.scss';

// Descripción: Este componente renderiza todo el componente de pie chart

const PieGraphics = ({
  geo,
  zone,
  states,
  country,
  account,
  unison,
  topAccount,
}) => {
  // Hooks
  const { data, isLoading, isSuccess, isError, filters, setFilters } =
    useZonesImplementations();
  const [isAnimationFinished, setIsAnimationFinished] = useState(false);

  useEffect(() => {
    setFilters({
      ...filters,
      geo,
      zone,
      states,
      country,
      account,
      unison,
      topAccount,
    });
  }, [geo, zone, country, states, account, unison, topAccount]);

  // Funciones
  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
  }) => {
    if (!isAnimationFinished) return null;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return percent !== 0 ? (
      <Text
        x={x}
        y={y}
        fill='white'
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline='central'
        style={{ pointerEvents: 'none' }}
      >
        {`${(percent * 100).toFixed(0)}%`}
      </Text>
    ) : null;
  };
  const categoryData =
    data?.data?.categories &&
    Object.entries(data.data.categories).map(([name, value]) => ({
      name,
      value,
    }));

  const colorMap = {
    ENDCAP: Colors.colorEndcap,
    TABLE: Colors.colorTable,
    BATTLESTATION: Colors.colorBattlestation,
  };

  const renderCustomizedLegend = (props) => {
    const { payload } = props;
    const customLegend = payload.map((entry) => {
      let implementation = '';
      let color = '';

      switch (entry.value) {
        case 'ENDCAP':
          implementation = 'Endcap';
          color = colorMap.ENDCAP;
          break;
        case 'TABLE':
          implementation = 'Table';
          color = colorMap.TABLE;
          break;
        case 'BATTLESTATION':
          implementation = 'Battlestation';
          color = colorMap.BATTLESTATION;
          break;
        default:
          implementation = entry.value;
          color = entry.color;
          break;
      }

      return { ...entry, value: implementation, color };
    });

    return (
      <div
        className=''
        style={{
          display: 'flex',
          flexDirection: 'row-reverse',
          gap: '10px',
          alignItems: 'center',
          justifyContent: 'start',
          position: 'absolute',
          top: 32,
          left: 0,
        }}
      >
        {customLegend.map((entry, index) => (
          <div
            key={`item-${index}`}
            style={{
              color: Colors.colorClaro,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              padding: '10px 0 48px',
              fontSize: '11px',
              textTransform: 'capitalize',
            }}
          >
            <div
              style={{
                margin: '0 5px',
                width: '10px',
                height: '10px',
                backgroundColor: entry.color,
              }}
            />
            <span>{entry.value}</span>
          </div>
        ))}
      </div>
    );
  };
  // Renders
  return (
    <>
      <Graphicscontainers
        footerAlign='flex-start'
        headerTitle='Zones Implementation Type'
        indicators=''
      >
        <>
          {isLoading && (
            <Lottie
              style={{ width: '60%', margin: '0 auto' }}
              animationData={LottieLoader}
            />
          )}
          {isSuccess &&
            (data.data?.total === 0 || data.data?.total === undefined) && (
              <p
                style={{
                  width: '100%',
                  textAlign: 'center',
                  fontSize: '16px',
                  color: '#fff',
                }}
              >
                No data available
              </p>
            )}
          {isSuccess && data.data?.total > 0 && (
            <ResponsiveContainer width='100%' height='100%'>
              <PieChart>
                <Pie
                  cx='50%'
                  cy='50%'
                  data={categoryData}
                  dataKey='value'
                  outerRadius={100}
                  labelLine={false}
                  label={renderCustomizedLabel}
                  onAnimationStart={() => setIsAnimationFinished(false)}
                  onAnimationEnd={() => setIsAnimationFinished(true)}
                >
                  {categoryData &&
                    categoryData?.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={colorMap[entry.name]}
                        stroke='none'
                      />
                    ))}
                </Pie>
                <Legend
                  content={renderCustomizedLegend}
                  verticalAlign='bottom'
                  align='left'
                />
                <Tooltip
                  offset={-70}
                  content={
                    <TooltipChart simbol='%' categoryData={categoryData} />
                  }
                  allowEscapeViewBox={{ x: true, y: true }}
                />
              </PieChart>
            </ResponsiveContainer>
          )}

          {isError && (
            <Lottie
              style={{ width: '10%', margin: '0 auto' }}
              animationData={LottieError}
            />
          )}
        </>
      </Graphicscontainers>
    </>
  );
};

// PieGraphics.propTypes = {
//   colors: PropTypes.arrayOf(PropTypes.string).isRequired,
// };

export default PieGraphics;

import React, { useEffect, useState } from 'react';
import ReactSelect from 'react-select';

// Descripción: Filtros de from y to para el chart

const FiltersCharts = ({ data, isLoading, isSuccess, filters, setFilters }) => {
  const [fromOptions, setFromOptions] = useState([]);
  const [toOptions, setToOptions] = useState([]);

  const [fromSelected, setFromSelected] = useState({
    value: filters.from || '',
    label: 'From',
  });
  const [toSelected, setToSelected] = useState({
    value: filters.to || '',
    label: 'To',
  });

  const handleChange = ({ from, to }) => {
    if (from) {
      setToSelected({ value: '', label: 'To' });
      setFromSelected(from);
      setFilters({ ...filters, from: from.value });
    }

    if (to) {
      setToSelected(to);
      setFilters({ ...filters, from: fromSelected.value, to: to.value });
    }
  };

  useEffect(() => {
    if (isSuccess && !isLoading) {
      const period = data.specificPeriod;

      if (period && fromSelected.value === '') {
        setFromOptions([]);

        period.all_options.forEach((item) => {
          setFromOptions((fromOptions) => [
            ...fromOptions,
            { value: item, label: item.toUpperCase() },
          ]);
        });
      }

      if (period && fromSelected.value !== '') {
        setToOptions([]);

        period.period_options.forEach((item) => {
          setToOptions((toOptions) => [
            ...toOptions,
            { value: item, label: item.toUpperCase() },
          ]);
        });
      }
    }
  }, [fromSelected, data, isSuccess, isLoading]);

  // Renders
  return (
    <>
      <ReactSelect
        id='from'
        name='from'
        options={fromOptions}
        isSearchable
        className='filter__select'
        loadingMessage='Loading...'
        classNamePrefix='filter__select'
        isLoading={isLoading}
        value={fromSelected}
        onChange={(value) => handleChange({ from: value })}
      />

      <ReactSelect
        id='to'
        name='to'
        options={toOptions}
        isSearchable
        className='filter__select'
        loadingMessage='Loading...'
        classNamePrefix='filter__select'
        isLoading={isLoading}
        value={toSelected}
        // isDisabled={
        //   !fromSelected.value ||
        //   !graphicsPerCountry.data.specificPeriod.period_options.length
        // }
        onChange={(value) => handleChange({ to: value })}
      />
    </>
  );
};

export default FiltersCharts;

/**
 * Descripcion del helper: Convierte una latitud y una longitud en un valor numerico para asignarlo a una esfera.
 *
 * Implementacion: Se importa y se le pasa como parametro latitud, longitud y radio.
 */

const CalcPosFromLatLonRad = (lat, lon, radius) => {
  const phi = (90 - lat) * (Math.PI / 180);
  const theta = (lon + 180) * (Math.PI / 180);

  const x = -(radius * Math.sin(phi) * Math.cos(theta));
  const z = radius * Math.sin(phi) * Math.sin(theta);
  const y = radius * Math.cos(phi);

  return [x, y, z];
};

export default CalcPosFromLatLonRad;

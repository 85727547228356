/**
 * Descripcion del helper: Este helper reduce la cantidad de caractares de un numero y lo pasa a letras K, M, B, etc...
 *
 * Implementacion: Se importa y como unico parametro se pasa el numero.
 */

const ResumeNumbers = (num = 0) => {
  const formater = Intl.NumberFormat('en', {
    notation: 'compact',
  });

  return formater.format(num); // Si no hay resultado, retorna el valor de entrada.
};

export default ResumeNumbers;

import React from 'react';
import { Marker, InfoWindow } from '@react-google-maps/api';
import Colors from '../../assets/scss/variables/colors.module.scss';

/**
 * Descripción: Este componente es de uso unico para el mapa, renderiza los marcadores
 *
 * Implementación: Se importa y se usa pasandole el item como parametro {data.map((item) =>  <Markers item={item} key={item.id} openTooltip={openTooltip} setOpenTooltip={setOpenTooltip} /> ))}
 */

const Markers = ({ item, zones, country, setCountry }) => {
  // Contextos
  const {
    colorClaro,
    colorSecundario,
    colorVariante,
    colorVarianteSeis,
    colorUltra,
  } = Colors;

  // Funciones
  const getMarketIcon = (item) => {
    let fillColor = colorClaro; // Default color

    if (item.zone.length > 1 && zones === 'all') {
      fillColor = colorClaro;
    } else if (
      item.zone.includes('evo') &&
      (zones === 'evo' || zones === 'all')
    ) {
      fillColor = colorSecundario;
    } else if (
      item.zone.includes('gaming') &&
      (zones === 'gaming' || zones === 'all')
    ) {
      fillColor = colorVariante;
    } else if (
      item.zone.includes('unison riser') &&
      (zones === 'unison riser' || zones === 'all')
    ) {
      fillColor = colorVarianteSeis;
    } else if (
      item.zone.includes('ultra') &&
      (zones === 'ultra' || zones === 'all')
    ) {
      fillColor = colorUltra;
    }

    return {
      path: 'M-5 -5 L5 -5 L5 5 L-5 5 Z',
      fillColor,
      fillOpacity: 1,
      strokeWeight: zones === 'ultra' ? 1 : 0,
      strokeColor: zones === 'ultra' ? 'white' : '',
      scale: zones === 'all' ? 1 : item.zone.includes(zones) ? 1 : 0,
    };
  };

  const handleClick = (item) => {
    setCountry(item);
  };

  const handleClose = () => {
    setCountry(null);
  };

  return (
    <Marker
      clickable
      title={item.name}
      animation='BOUNCE'
      onClick={() => handleClick(item)} // handle click function needs to be defined
      position={{ lat: item.latlons[0][0], lng: item.latlons[0][1] }}
      icon={getMarketIcon(item)}
    >
      {country && country.id === item.id ? ( // need to check if country is defined
        <InfoWindow
          onCloseClick={handleClose}
          position={{ lat: item.latlons[0][0], lng: item.latlons[0][1] }}
        >
          <div className='tooltip'>
            <h3>{item.name}</h3>
          </div>
        </InfoWindow>
      ) : null}
    </Marker>
  );
};

Markers.propTypes = {};

Markers.defaultProps = {};

export default Markers;

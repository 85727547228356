import React, { useEffect, useState } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import TooltipChartModals from './TooltipChartLines';
import SeparatorNumber from '../../helpers/SeparatorNumber';
import Colors from '../../assets/scss/variables/colors.module.scss';

/**
 * Descripción: La descripción de por qué estamos haciendo este componente y que resuelve.
 *
 * Implementación: Descripción sobre cómo puedes implementar el componente.
 */

const ChartImpressions = ({ chart, zone }) => {
  const [data, setData] = useState([]);

  const {
    colorSecundario,
    colorVariante,
    colorClaro,
    colorVarianteCinco,
    colorVarianteSeis,
    colorVarianteNueve,
  } = Colors;

  useEffect(() => {
    if (chart) {
      if (chart.chronologically) {
        // Conertimos los valores en un array
        const arrayOfValues = Object.values(chart.chronologically);

        // Organizamos el array por Q
        const sortArray = arrayOfValues.sort((a, b) => {
          const valueA = a.order.toUpperCase();
          const valueB = b.order.toUpperCase();

          if (valueA < valueB) {
            return -1;
          }
          if (valueA > valueB) {
            return 1;
          }

          // names must be equal
          return 0;
        });

        // Lo seteamos
        setData(sortArray);
      }
    }
  }, [chart]);

  // Renders
  return (
    <>
      <ResponsiveContainer width='100%' height='100%'>
        <LineChart
          data={data}
          margin={{
            top: 5,
            right: 5,
            left: 5,
            bottom: 10,
          }}
        >
          <CartesianGrid horizontal={false} stroke={colorClaro} />
          <YAxis
            stroke={colorClaro}
            type='number'
            allowDecimals
            tickFormatter={SeparatorNumber}
          />

          <XAxis
            name='period'
            dataKey='period'
            stroke={colorClaro}
            tickMargin={11}
          />

          <Tooltip
            content={<TooltipChartModals />}
            offset={-70}
            allowEscapeViewBox={{ x: true, y: true }}
          />

          {zone === 'all' ? (
            <Line
              type='monotone'
              dataKey='evoImpressions'
              stroke={colorSecundario}
              strokeWidth={2}
            />
          ) : (
            zone === 'evo' && (
              <Line
                type='monotone'
                dataKey='evoImpressions'
                stroke={colorVarianteCinco}
                strokeWidth={2}
              />
            )
          )}

          {zone === 'all' ? (
            <Line
              dataKey='gamingImpressions'
              strokeWidth={2}
              type='monotone'
              stroke={colorVariante}
            />
          ) : (
            zone === 'gaming' && (
              <Line
                type='monotone'
                dataKey='gamingImpressions'
                stroke={colorVarianteCinco}
                strokeWidth={2}
              />
            )
          )}

          {zone === 'all' ? (
            <Line
              dataKey='unisonImpressions'
              strokeWidth={2}
              type='monotone'
              stroke={colorVarianteSeis}
            />
          ) : (
            zone === 'unison riser' && (
              <Line
                type='monotone'
                dataKey='unisonImpressions'
                stroke={colorVarianteCinco}
                strokeWidth={2}
              />
            )
          )}

          {zone === 'all' ? (
            <Line
              dataKey='ultraImpressions'
              strokeWidth={2}
              type='monotone'
              stroke={colorVarianteNueve}
            />
          ) : (
            zone === 'ultra' && (
              <Line
                type='monotone'
                dataKey='ultraImpressions'
                stroke={colorVarianteCinco}
                strokeWidth={2}
              />
            )
          )}
        </LineChart>
      </ResponsiveContainer>
    </>
  );
};

export default ChartImpressions;

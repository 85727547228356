import IsNumeric from './IsNumeric';

function IsNegative(number = 0) {
  if (IsNumeric(number) && number < 0) {
    return true;
  }
  return false;
}

export default IsNegative;

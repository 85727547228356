import React from 'react';
import SeparatorNumber from '../../helpers/SeparatorNumber';

/**
 * Descripción: Tooltip customizado para el chart
 *
 * Implementación: Se usa dentro de <Tooltip content={<TooltipChart />} />
 */

const TooltipChart = ({ active, payload, simbol, categoryData }) => {
  if (active && payload && payload.length) {
    // calculamos valor total para obtener el % de cada elemento
    const total = Array.isArray(categoryData)
      ? categoryData.reduce((sum, entry) => sum + entry.value, 0)
      : 0;
    const { value } = payload[0];
    const percentage = ((value / total) * 100).toFixed(2);

    return (
      <div className='tooltipchart'>
        {payload.map(({ color, _value }, index) => {
          return (
            <div key={index} className='tooltipchart__content'>
              <p>
                <span
                  className='spark'
                  style={{ marginRight: 5, backgroundColor: color }}
                />
                {`${SeparatorNumber(percentage)} ${simbol || ''} `}
              </p>
            </div>
          );
        })}

        <div className='tooltipchart__arrow' />
      </div>
    );
  }

  return null;
};

TooltipChart.propTypes = {};

TooltipChart.defaultProps = {};

export default TooltipChart;

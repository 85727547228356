import React, { useState, useContext, useEffect } from 'react';
import { useLocation } from 'wouter';
import { Outlet } from 'react-router-dom';
import FiltersZones from '../contexts/FiltersZonesContext';
import CountrySelectedContext from '../contexts/CountrySelectedContext';
import Aside from './Aside/Aside';
import Footer from './Footer/Footer';
import HelpScreen from '../components/HelpScreen';
import RetailerScreen from '../containers/Modals/RetailerScreen';
import ChartsScreen from '../containers/Modals/ChartsScreen';
import ImageViewer from '../components/ImageViewer';

const Layout = () => {
  const [fullView, setFullView] = useState(false);
  const [show, setShow] = useState(false);
  const [expandAside, setExpandAside] = useState(false);
  const [showHelpScreen, setShowHelpScreen] = useState(false);
  const [graphicsScreen, setGraphicsScreen] = useState(false);
  const [imageToOpen, setImageToOpen] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [isInputBlurred, setIsInputBlurred] = useState(true);

  const setBlurInput = (value) => {
    setIsInputBlurred(value);
  };

  const { zones, setZones } = useContext(FiltersZones);
  const { country, setCountry, countryFocus } = useContext(
    CountrySelectedContext
  );

  // Hooks
  const [location] = useLocation();

  useEffect(() => {
    if (country !== null && country !== 0) {
      // displayToast();
      setShow(true);
    } else {
      setFullView(false);
    }
  }, [country]);

  return (
    <>
      {/* Loader */}
      {location !== '/' ? null : (
        <>
          {/* <Loader progress={progress} loaded={loaded} total={total} errors={errors} /> */}

          {/* Pantalla de ayuda */}
          <HelpScreen
            showHelpScreen={showHelpScreen}
            setShowHelpScreen={setShowHelpScreen}
          />

          {/* Visor de imagenes */}
          <ImageViewer
            imageToOpen={imageToOpen}
            setImageToOpen={setImageToOpen}
          />

          {/* Detalle retailer */}
          {showHelpScreen && country && fullView && !graphicsScreen ? (
            <RetailerScreen
              show={show}
              zones={zones}
              setShow={setShow}
              country={country}
              setCountry={setCountry}
              expandAside={expandAside}
              imageToOpen={imageToOpen}
              setFullView={setFullView}
              setExpandAside={setExpandAside}
              setImageToOpen={setImageToOpen}
              setGraphicsScreen={setGraphicsScreen}
              setBlurInput={setBlurInput}
            />
          ) : null}

          {/* Visor de chart */}
          {!show && graphicsScreen && showHelpScreen ? (
            <ChartsScreen
              zones={zones}
              setShow={setShow}
              country={country}
              setGraphicsScreen={setGraphicsScreen}
            />
          ) : null}
        </>
      )}

      {/* Aside  */}
      <Aside
        show={show}
        zones={zones}
        country={country}
        setShow={setShow}
        setZones={setZones}
        fullView={fullView}
        setCountry={setCountry}
        expandAside={expandAside}
        setFullView={setFullView}
        countryFocus={countryFocus}
        setExpandAside={setExpandAside}
        showHelpScreen={showHelpScreen}
        setGraphicsScreen={setGraphicsScreen}
        setShowHelpScreen={setShowHelpScreen}
      />

      {/* Children */}
      <div
        style={{
          transform: show ? 'translateX(320px)' : 'translateX(0px)',
          transition: 'all 0.3s ease-in-out',
        }}
      >
        <Outlet />
      </div>

      {/* Footer */}
      {!fullView && location === '/' ? <Footer show={show} /> : null}
    </>
  );
};

export default Layout;

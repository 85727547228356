import { useState } from 'react';
import { useQuery } from 'react-query';
import api from '../services/api.service';
import { GRAPHICS_KEY } from '../constants/api.type';
import DeleteEmptyKeys from '../helpers/DeleteEmptyKeys';

// Descripcion del hook: Este hook se encarga de hacer el llamado a la api

export const useGraphicsPerCountry = () => {
  const [filters, setFilters] = useState({
    country: null,
    from: null,
    to: null,
  });

  const { data, isLoading, isSuccess, isError } = useQuery(
    [GRAPHICS_KEY, JSON.stringify(filters)],
    async () => {
      // Filter out null values
      const filteredFilters = DeleteEmptyKeys(filters);

      // Now use filteredFilters in your API call
      return api.graphics.getPerCountry(filteredFilters);
    }
  );

  return { data, isLoading, isSuccess, isError, filters, setFilters };
};

const useGraphics = () => {
  const [filters, setFilters] = useState({
    from: null,
    to: null,
  });

  const { data, isLoading, isSuccess, isError } = useQuery(
    [GRAPHICS_KEY, JSON.stringify(filters)],
    async () => {
      // Filter out null values
      const filteredFilters = DeleteEmptyKeys(filters);

      // Now use filteredFilters in your API call
      return api.graphics.getAll(filteredFilters);
    }
  );

  return { data, isLoading, isSuccess, isError, filters, setFilters };
};

export default useGraphics;

import React, { useEffect, useContext, useState, useRef } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import Img from 'react-cool-img';
import Lottie from 'lottie-react';
import PropTypes from 'prop-types';
import GraphicsContext from '../../contexts/GraphicsContext';
import { useRetailersPerCountry } from '../../hooks/useRetailers';
import { useGraphicsPerCountry } from '../../hooks/useGraphics';
import { PATH_IMAGES, PATH_VIDEOS } from '../../constants/api.type';
import CardRetailer from '../../components/CardRetailer';
import ModalButton from '../../components/Buttons/ModalButton';
import Loading from '../../assets/animations/spinner_loading.json';
import SearchIcon from '../../assets/images/icons/search__icon__input.svg';
import Placeholder from '../../assets/images/static/Placeholder.gif';
import ErrorImage from '../../assets/images/static/ErrorImage.jpg';
import CloseIcon from '../../assets/images/icons/close__icon.svg';
import ErrorIcon from '../../assets/images/icons/error_Icon.svg';

const RetailerScreen = ({
  show,
  zones,
  country,
  setShow,
  setCountry,
  setFullView,
  setBlurInput,
  expandAside,
  setImageToOpen,
  setGraphicsScreen,
}) => {
  const [userQuery, setUserquery] = useState('');
  const [limitResults, setLimitResults] = useState(6);
  const { countryInfo, setCountryInfo } = useContext(GraphicsContext);
  const panel = useRef(null);
  const { data, isLoading, isSuccess, isError, filters, setFilters } =
    useRetailersPerCountry();
  const graphicsPerCountry = useGraphicsPerCountry();
  const handleChange = (e) => {
    e.target.value !== 'evo' &&
      e.target.value !== 'gaming' &&
      e.target.value !== 'unison riser' &&
      setUserquery(e.target.value);
  };

  const onSubmit = () => {
    if (userQuery === '') {
      setFilters({ ...filters, keyword: null });
    } else {
      setFilters({ ...filters, keyword: userQuery.toLowerCase() });
    }
  };

  const handleClear = () => {
    setUserquery('');
    setFilters({ ...filters, keyword: null });
  };

  const handleFocus = () => {
    setBlurInput(true);
  };

  const handleClose = () => {
    setFullView(false);
  };

  const handleClick = (item) => {
    if (country.id === item.pin) {
      setCountry({
        ...country,
        retailer: {
          ...item,
        },
      });
    }
  };

  const handleClickGrahics = () => {
    setShow(!show);
    setGraphicsScreen(true);
    setCountryInfo({
      ...countryInfo,
      country,
      zone: zones,
      chart: graphicsPerCountry.data,
    });
  };

  const handleShowMore = () => {
    setLimitResults(limitResults + 4);
  };

  useEffect(() => {
    if (isSuccess) {
      if (country.id && data.implementations.length) {
        if (!country.retailer) {
          setCountry({
            ...country,
            retailer: {
              ...data.implementations[0],
            },
          });
        }
      }

      graphicsPerCountry.setFilters({
        ...graphicsPerCountry.filters,
        country: country.name,
      });
    }
  }, [country, isSuccess]);

  useEffect(() => {
    if (country) {
      setFilters({ ...filters, country: country.name });
    }
  }, [country]);

  return (
    <div className={`retailer ${expandAside ? 'isExpand' : ''}`}>
      <button className='retailer__close' onClick={handleClose}>
        <img src={CloseIcon} alt='Close Icon' />
      </button>

      {isLoading && (
        <>
          {/* Panel de resultados e input */}
          <div className='retailer__search'>
            <h3>Intel® Display Zone</h3>

            <div className='retailer__input'>
              <div className='retailer__input__square' />
              <input
                type='text'
                name='search'
                readOnly
                placeholder={`Search implementations in ${country?.name}`}
                autoComplete='new-password'
                autoCorrect='off'
              />

              <button data-type='submit'>
                <img src={SearchIcon} alt='' />
              </button>
            </div>

            <div className='searchresults__message'>
              <Lottie animationData={Loading} loop />
            </div>
          </div>

          {/* Panel de imagenes e informacion */}
          <div className='retailer__details'>
            <h1>Loading implementation...</h1>

            <div className='retailer__container'>
              <h2>Loading retailer namer...</h2>

              <div className='retailer__container__images'>
                {/* Foto izquierda */}
                <img src={ErrorImage} alt='image' />

                {/* Foto derecha o video */}
                <img src={ErrorImage} alt='image' />
              </div>
            </div>
          </div>
        </>
      )}

      {isSuccess && (
        <>
          {/* Panel de resultados e input */}
          <div className='retailer__search'>
            <h3>Intel® Display Zone</h3>

            <div className='retailer__input'>
              <div className='retailer__input__square' />
              <input
                type='text'
                name='search'
                value={userQuery}
                autoCorrect='off'
                onFocus={handleFocus}
                onChange={handleChange}
                autoComplete='new-password'
                placeholder={`Search implementations in ${country?.name}`}
              />

              {userQuery && filters.keyword ? (
                <button data-type='reset' onClick={handleClear}>
                  <AiOutlineClose />
                </button>
              ) : null}

              <button data-type='submit' onClick={onSubmit}>
                <img src={SearchIcon} alt='' />
              </button>
            </div>

            {/* Si no hay busqueda muestra los 6 primeros */}
            <div className='retailer__other' ref={panel}>
              <h4>
                Other Implementations in {country?.name}{' '}
                <span>({data.implementations_count} results)</span>
              </h4>

              {data.implementations
                .slice(0, limitResults)
                .map(
                  (item, index) =>
                    (zones === 'all' || item.zone === zones) && (
                      <CardRetailer
                        key={index}
                        item={item}
                        image={item?.images[0]?.name}
                        onClick={() => handleClick(item)}
                      />
                    )
                )}
            </div>

            {/* Boton para ver mas */}
            {limitResults <= data.implementations.length ? (
              <button
                className='searchresults__showmore'
                onClick={handleShowMore}
              >
                See more
              </button>
            ) : null}
          </div>

          {/* Panel de imagenes e informacion */}
          <div className='retailer__details'>
            <h1>{country?.retailer?.name || 'No Country data'}</h1>

            <div className='retailer__container'>
              <h2>{country?.retailer?.retailer || 'No retailers data'}</h2>

              <div className='retailer__container__images'>
                {/* Foto izquierda */}
                {country?.retailer?.images.length ? (
                  <>
                    {country.retailer.images[0] ? (
                      <Img
                        cache
                        error={ErrorImage}
                        src={PATH_IMAGES + country.retailer.images[0].name}
                        placeholder={Placeholder}
                        alt='REACT COOL IMG'
                        onClick={() =>
                          setImageToOpen(
                            PATH_IMAGES + country.retailer.images[0].name
                          )
                        }
                      />
                    ) : (
                      <img src={ErrorImage} alt='image' />
                    )}
                  </>
                ) : (
                  <>
                    <img src={ErrorImage} alt='image' />
                  </>
                )}

                {/* Foto derecha o video */}
                {country?.retailer?.videos.length ? (
                  <>
                    {country.retailer.videos[0] && (
                      <video
                        key={country.retailer.videos[0]?.id}
                        preload='true'
                        controls
                        disablePictureInPicture
                        playsInline
                        disableRemotePlayback
                        controlsList='nodownload'
                        style={{ background: 'black' }}
                      >
                        <source
                          // eslint-disable-next-line no-unsafe-optional-chaining
                          src={PATH_VIDEOS + country.retailer.videos[0]?.name}
                          type='video/mp4'
                        />
                        Your browser does not support the video tag.
                      </video>
                    )}
                  </>
                ) : (
                  country?.retailer?.images[1] && (
                    <Img
                      cache
                      error={ErrorImage}
                      src={PATH_IMAGES + country.retailer.images[1].name}
                      placeholder={Placeholder}
                      alt='REACT COOL IMG'
                      onClick={() =>
                        setImageToOpen(
                          PATH_IMAGES + country.retailer.images[1].name
                        )
                      }
                    />
                  )
                )}
              </div>

              {/* <ModalButton type='all' onClick={handleClickGrahics} /> */}
              <ModalButton
                type={zones === 'evo' ? 'evo' : 'all'}
                onClick={handleClickGrahics}
              />
            </div>
          </div>
        </>
      )}

      {isError && (
        <>
          {/* Panel de resultados e input */}
          <div className='retailer__search'>
            <h3>Intel® Display Zone</h3>

            <div className='retailer__input'>
              <div className='retailer__input__square' />
              <input
                type='text'
                name='search'
                readOnly
                placeholder={`Search implementations in ${country?.name}`}
                autoComplete='new-password'
                autoCorrect='off'
              />

              <button data-type='reset'>
                <AiOutlineClose />
              </button>

              <button data-type='submit'>
                <img src={SearchIcon} alt='' />
              </button>
            </div>

            <div className='retailer__message'>
              <img src={ErrorIcon} alt='' />
              <p>The page you are looking for is temporarily unavailable.</p>
            </div>
          </div>

          {/* Panel de imagenes e informacion */}
          <div className='retailer__details'>
            <div className='retailer__container'>
              <div className='retailer__container__images'>
                {/* Foto izquierda */}
                <img src={ErrorImage} alt='image' />

                {/* Foto derecha o video */}
                {/* <img src={ErrorImage} alt='image' /> */}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

RetailerScreen.propTypes = {
  /** country es el objeto con toda la informacion del retailer */
  country: PropTypes.object.isRequired,
  /** Funcion para enviar la pocision del marcador, esto acerca o aleja la cam */
  setCountry: PropTypes.func.isRequired,
  /** El modal de pantalla completa */
  setFullView: PropTypes.func.isRequired,
};

export default RetailerScreen;

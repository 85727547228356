import React, { useState, useCallback, memo, useContext } from 'react';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import CountrySelectedContext from '../../contexts/CountrySelectedContext';
import FiltersZonesContext from '../../contexts/FiltersZonesContext';
import useCountries from '../../hooks/useCountries';
import Panning from './Panning';
import Markers from './Markers';
import {
  CENTER,
  MAP_ID,
  GOOGLE_MAPS_ID,
  GOOGLE_MAPS_API_KEY,
} from '../../constants/maps.type';
import ErrorMessage from '../../containers/ErrorMessage';

// Descripción: Mapa en 2D con el proovedor de google

const Maps = () => {
  const [openTooltip, setOpenTooltip] = useState(null);
  const [, setMap] = useState(null);

  const { country, setCountry } = useContext(CountrySelectedContext);
  const { zones } = useContext(FiltersZonesContext);

  const { data, isSuccess, isError } = useCountries();

  const options = {
    minZoom: 3,
    maxZoom: 5,
    zoomControl: false,
    mapTypeControl: false,
    mapId: GOOGLE_MAPS_ID,
    disableDefaultUI: false,
    streetViewControl: false,
    fullscreenControl: false,
    gestureHandling: 'greedy',
    backgroundColor: 'black',
    restriction: {
      latLngBounds: {
        north: 80, // Límite norte de visualización (latitud)
        south: -80, // Límite sur de visualización (latitud)
        east: 180, // Límite este de visualización (longitud)
        west: -180, // Límite oeste de visualización (longitud)
      },
    },
  };

  const { isLoaded } = useJsApiLoader({
    id: MAP_ID,
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
  });

  const onLoad = useCallback((map) => {
    const bounds = new window.google.maps.LatLngBounds(CENTER);
    map.fitBounds(bounds);

    setMap(map);
  }, []);

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  if (isError) {
    return <ErrorMessage />;
  }

  return isLoaded && isSuccess ? (
    <GoogleMap
      zoom={3}
      center={CENTER}
      onLoad={onLoad}
      options={options}
      onUnmount={onUnmount}
      mapContainerClassName='maps__container'
    >
      <Panning country={country} />

      {data.map((item) => (
        <Markers
          item={item}
          key={item.id}
          zones={zones}
          country={country}
          setCountry={setCountry}
          openTooltip={openTooltip}
          setOpenTooltip={setOpenTooltip}
        />
      ))}
    </GoogleMap>
  ) : (
    <>Cargando...</>
  );
};

export default memo(Maps);

// Refactorización:
function SeparatorNumber(num) {
  // Si el parámetro es un número, lo convierte a un string con separadores de miles.
  if (num) return num.toLocaleString('en-EN');

  // Si el número es igual a 0, devuelve 0.
  return 0;
}
// Exporta la función para que pueda ser usada en otros archivos.
export default SeparatorNumber;

import React from 'react';

const Checkboxs = ({ label, name, value, onChange, checked, disabled }) => {
  return (
    <div className='pretty p-default  p-fill  p-smooth p-outline'>
      <input
        name={name}
        value={value}
        type='checkbox'
        aria-label={label}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      <div className='state p-info'>
        <label htmlFor={name}>{label}</label>
      </div>
    </div>
  );
};

export default Checkboxs;

import React from 'react';
import FlipNumbers from 'react-flip-numbers';
import PropTypes from 'prop-types';
import Colors from '../assets/scss/variables/colors.module.scss';

/**
 * Descripción: Este componente se encarga de realizar una animacion de contador
 *
 * Implementación: Se importa como componente y se les pasa los pops mencionados en los proprypes
 */

const CounterNumbers = ({
  width,
  height,
  delay,
  color,
  numbers,
  background,
}) => {
  return (
    <FlipNumbers
      play
      width={width}
      height={height}
      delay={delay}
      color={color}
      numbers={numbers}
      background={background}
    />
  );
};

CounterNumbers.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  delay: PropTypes.number,
  color: PropTypes.string,
  numbers: PropTypes.string,
  background: PropTypes.string,
};

CounterNumbers.defaultProps = {
  width: 26,
  height: 40,
  delay: 0.1,
  color: Colors.colorClaro,
  numbers: '0',
  background: '',
};

export default CounterNumbers;

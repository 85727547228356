import React, { createContext, useState } from 'react';

// Descripcion del context: Este contexto recibe los datos necesarios para hacer funcionar los charts

const GraphicsContext = createContext();

export function GrapihcsProvider({ children }) {
  const [countryInfo, setCountryInfo] = useState({
    zone: '',
    chart: null,
    country: null,
  });

  return (
    <GraphicsContext.Provider value={{ countryInfo, setCountryInfo }}>
      {children}
    </GraphicsContext.Provider>
  );
}
export default GraphicsContext;

import React from 'react';
import Checkbox from './Checkbox';

export default function CheckboxLabel({
  labelText,
  id,
  checked,
  onChange,
  className,
  ...rest
}) {
  return (
    // <label htmlFor={id} className='checkbox-label'>
    <label htmlFor={id} className={`${className}`}>
      <div className='checkbox-label__container'>
        <Checkbox id={id} checked={checked} onChange={onChange} {...rest} />
      </div>
      <span>{labelText}</span>
    </label>
  );
}

import React, { useEffect, useState } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import SeparatorNumber from '../../helpers/SeparatorNumber';
import TooltipChartModals from './TooltipChartLines';
import Colors from '../../assets/scss/variables/colors.module.scss';

// Descripción: La descripción de por qué estamos haciendo este componente y que resuelve.

const ChartStores = ({ chart, zone }) => {
  const [data, setData] = useState([]);

  const {
    colorSecundario,
    colorVariante,
    colorClaro,
    colorVarianteSeis,
    colorVarianteNueve,
  } = Colors;

  useEffect(() => {
    if (chart) {
      // Conertimos los valores en un array

      if (chart.chronologically) {
        const arrayOfValues = Object.values(chart.chronologically);

        // Organizamos el array por Q
        const sortArray = arrayOfValues.sort((a, b) => {
          const valueA = a.order.toUpperCase();
          const valueB = b.order.toUpperCase();

          if (valueA < valueB) {
            return -1;
          }
          if (valueA > valueB) {
            return 1;
          }

          // names must be equal
          return 0;
        });

        // Lo seteamos
        setData(sortArray);
      }
    }
  }, [chart]);

  return (
    <>
      <ResponsiveContainer width='100%' height='100%'>
        <LineChart
          data={data}
          margin={{
            top: 5,
            right: 5,
            left: 5,
            bottom: 10,
          }}
        >
          <CartesianGrid horizontal={false} stroke={colorClaro} />
          <YAxis
            stroke={colorClaro}
            type='number'
            allowDecimals
            tickFormatter={SeparatorNumber}
          />
          <XAxis
            name='period'
            dataKey='period'
            stroke={colorClaro}
            tickMargin={11}
          />

          <Tooltip
            content={<TooltipChartModals />}
            offset={-70}
            allowEscapeViewBox={{ x: true, y: true }}
          />

          {(zone === 'all' || zone === 'evo') && (
            <Line
              type='monotone'
              dataKey='evo'
              stroke={colorSecundario}
              strokeWidth={2}
            />
          )}

          {(zone === 'all' || zone === 'gaming') && (
            <Line
              dataKey='gaming'
              strokeWidth={2}
              type='monotone'
              stroke={colorVariante}
            />
          )}

          {zone === 'unison riser' && (
            <Line
              dataKey='unison riser'
              strokeWidth={2}
              type='monotone'
              stroke={colorVarianteSeis}
            />
          )}

          {(zone === 'all' || zone === 'ultra') && (
            <Line
              dataKey='ultra'
              strokeWidth={2}
              type='monotone'
              stroke={colorVarianteNueve}
            />
          )}
        </LineChart>
      </ResponsiveContainer>
    </>
  );
};

export default ChartStores;

import React, { useEffect, useContext, useState } from 'react';
import Proptypes from 'prop-types';
import GraphicsContext from '../../contexts/GraphicsContext';
import useGraphics from '../../hooks/useGraphics';
import ModalButtons from '../Buttons/ModalButton';
import IsNegative from '../../helpers/IsNegative';
import CounterNumbers from '../CounterNumbers';
import ModalArrowIconAll from '../../assets/images/icons/modal__arrow__all@2x.png';
import ModalArrowIconEvo from '../../assets/images/icons/modal__arrow__evo@2x.png';
import ModalArrowIconGaming from '../../assets/images/icons/modal__arrow__gaming@2x.png';
import ModalArrowIconUnison from '../../assets/images/icons/modal__arrow__unison.svg';
import ModalArrowIconUltra from '../../assets/images/icons/modal__arrow__ultra.svg';

/**
 * Descripción: Este componente se encarga de mostrar informacion al usuario cuando se clickquean algunos de los filtros iniciales <Footer/>.
 */

const ModalImplementations = ({
  show,
  zones,
  country,
  setShow,
  setGraphicsScreen,
}) => {
  const [QoverQ, setQoverQ] = useState({});
  const { countryInfo, setCountryInfo } = useContext(GraphicsContext);
  const { data, isLoading, isSuccess } = useGraphics();
  const handleClick = () => {
    setShow(!show);
    setGraphicsScreen(true);
    setCountryInfo({
      ...countryInfo,
      country,
      zone: zones,
      chart: data,
    });
  };

  useEffect(() => {
    if (isSuccess && data) {
      setQoverQ(data.data.implementation.QoverQ);
    }
  }, [isSuccess, zones]);

  return (
    <div className={`modalimp ${isLoading ? 'loading' : ''}`}>
      {isLoading && (
        <div className={`modalimp__container ${zones}`}>
          <div className='modalimp__container__square' />

          <div className='lds-roller'>
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
          </div>
        </div>
      )}

      {isSuccess && (
        <>
          <div className={`modalimp__container ${zones}`}>
            <div className='modalimp__container__square' />
            {/* Valor total */}
            <h1>
              {zones === 'all' && (
                <CounterNumbers
                  numbers={String(data.data.implementation.total)}
                />
              )}

              {zones === 'evo' && (
                <CounterNumbers
                  numbers={String(data.data.implementation.evoTotal)}
                />
              )}

              {zones === 'gaming' && (
                <CounterNumbers
                  numbers={String(data.data.implementation.gamingTotal)}
                />
              )}

              {zones === 'unison riser' && (
                <CounterNumbers
                  numbers={String(data.data.implementation.unisonTotal)}
                />
              )}

              {zones === 'ultra' && (
                <CounterNumbers
                  numbers={String(data.data.implementation.ultraTotal)}
                />
              )}
            </h1>

            <p>implementations</p>

            <div className='modalimp__container__text'>
              {/* Porcentaje */}
              <h2>
                {zones === 'all'
                  ? QoverQ?.total?.toFixed()
                  : zones === 'evo'
                  ? QoverQ?.evo?.toFixed()
                  : zones === 'gaming'
                  ? QoverQ?.gaming?.toFixed()
                  : zones === 'unison riser'
                  ? QoverQ?.['unison riser']
                  : zones === 'ultra'
                  ? QoverQ?.ultra?.toFixed()
                  : '0'}
                %
              </h2>{' '}
              {/* Icono general */}
              {zones === 'all' && (
                <img
                  className={IsNegative(QoverQ?.total) ? 'negative' : ''}
                  src={ModalArrowIconAll}
                  alt=''
                />
              )}
              {/* Icono para evo */}
              {zones === 'evo' && (
                <img
                  className={IsNegative(QoverQ?.evo) ? 'negative' : ''}
                  src={ModalArrowIconEvo}
                  alt=''
                />
              )}
              {/* Icono para gaming */}
              {zones === 'gaming' && (
                <img
                  className={IsNegative(QoverQ?.gaming) ? 'negative' : ''}
                  src={ModalArrowIconGaming}
                  alt=''
                />
              )}
              {/* Icono para unison riser */}
              {zones === 'unison riser' && (
                <img
                  className={
                    IsNegative(QoverQ?.['unison riser']) ? 'negative' : ''
                  }
                  src={ModalArrowIconUnison}
                  alt=''
                />
              )}
              {/* Icono para ultra */}
              {zones === 'ultra' ? (
                <img
                  className={IsNegative(QoverQ?.ultra) ? 'negative' : ''}
                  src={ModalArrowIconUltra}
                  alt=''
                />
              ) : null}
              <span>QoQ</span>
            </div>
          </div>

          <div className='modalimp__button'>
            <ModalButtons type={zones} onClick={() => handleClick()} />
          </div>
        </>
      )}
    </div>
  );
};

ModalImplementations.propTypes = {
  zones: Proptypes.string.isRequired,
};

export default ModalImplementations;

import React, { useState } from 'react';
import ModalExportData from '../Modals/ModalExportData';

export default function ExportData() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    // eslint-disable-next-line no-console
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <button className='export-data__button' onClick={openModal}>
        Export Data
      </button>
      {isModalOpen && (
        <ModalExportData
          handleClose={handleClose}
          setIsModalOpen={setIsModalOpen}
        />
      )}
    </>
  );
}

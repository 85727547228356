import { useState } from 'react';
import { useQuery } from 'react-query';
import api from '../services/api.service';
import {
  ANALYTICS_GEO_KEY,
  ANALYTICS_BAR_KEY,
  ANALYTICS_PIE_KEY,
  ANALYTICS_ACCOUNT_KEY,
  ANALYTICS_ZONES_COVERAGE_OVER_TIME_KEY,
  ANALYTICS_ZONES_IMPLEMENTATION_KEY,
  EXPORT_BASE_URL_API,
} from '../constants/api.type';
import DeleteEmptyKeys from '../helpers/DeleteEmptyKeys';

//  Descripcion del hook: Este hook se encarga de hacer el llamado a la api

export const useZonesCoverageOverTime = () => {
  const [filters, setFilters] = useState({
    account: null,
    country: null,
    geo: null,
    zone: null,
    from: null,
    to: null,
  });

  const { data, isLoading, isSuccess, isError } = useQuery(
    [ANALYTICS_ZONES_COVERAGE_OVER_TIME_KEY, JSON.stringify(filters)],
    async () => {
      // Filter out null values
      const filteredFilters = DeleteEmptyKeys(filters);

      // Now use filteredFilters in your API call
      return api.analytics.getZonesCoverageOverTime(filteredFilters);
    }
  );

  return { data, isLoading, isSuccess, isError, filters, setFilters };
};

export const useZonesCoverage = () => {
  const [filters, setFilters] = useState({
    account: null,
    country: null,
    geo: null,
    zone: null,
    from: null,
    to: null,
  });

  const { data, isLoading, isSuccess, isError } = useQuery(
    [ANALYTICS_PIE_KEY, JSON.stringify(filters)],
    async () => {
      // Filter out null values
      const filteredFilters = DeleteEmptyKeys(filters);

      // Now use filteredFilters in your API call
      return api.analytics.getZonesCoverage(filteredFilters);
    }
  );

  return { data, isLoading, isSuccess, isError, filters, setFilters };
};

export const useZonesGrowthOverTime = () => {
  const [filters, setFilters] = useState({
    account: null,
    country: null,
    geo: null,
    zone: null,
    from: null,
    to: null,
  });

  const { data, isLoading, isSuccess, isError } = useQuery(
    [ANALYTICS_BAR_KEY, JSON.stringify(filters)],
    async () => {
      // Filter out null values
      const filteredFilters = DeleteEmptyKeys(filters);

      // Now use filteredFilters in your API call
      return api.analytics.getZonesGrowthOverTime(filteredFilters);
    }
  );

  return { data, isLoading, isSuccess, isError, filters, setFilters };
};

export const useAnalyticsByAccount = () => {
  const [filters, setFilters] = useState({
    account: null,
    country: null,
    geo: null,
    zone: 'all',
    page: '1',
  });

  const { data, isLoading, isSuccess, isError } = useQuery(
    [ANALYTICS_ACCOUNT_KEY, JSON.stringify(filters)],
    async () => {
      // Filter out null values
      const filtered = DeleteEmptyKeys(filters);

      // Now use filteredFilters in your API call
      return api.analytics.getByAccount(filtered);
    }
  );

  return { data, isLoading, isSuccess, isError, filters, setFilters };
};
export const useZonesImplementations = () => {
  const [filters, setFilters] = useState({
    account: null,
    country: null,
    geo: null,
    zone: null,
    states: null,
    from: null,
    to: null,
    unison: null,
    topAccount: null,
  });

  const { data, isLoading, isSuccess, isError } = useQuery(
    [ANALYTICS_ZONES_IMPLEMENTATION_KEY, JSON.stringify(filters)],
    async () => {
      // Filter out null values
      const filteredFilters = DeleteEmptyKeys(filters);

      // Now use filteredFilters in your API call
      return api.analytics.getZonesImplementation(filteredFilters);
    }
  );
  return { data, isLoading, isSuccess, isError, filters, setFilters };
};
const useAnalyticsByGeo = () => {
  const { data, isLoading, isSuccess, isError } = useQuery(
    [ANALYTICS_GEO_KEY],
    async () => api.analytics.getByGeo()
  );

  return { data, isLoading, isSuccess, isError };
};
export default useAnalyticsByGeo;

export const useExportUrl = () => {
  const baseUrl = EXPORT_BASE_URL_API;

  const createExportUrl = (selectedCheckboxes) => {
    const params = selectedCheckboxes.map((checkbox, index) => {
      let paramString = '';
      const paramName = `value${index + 1}`;
      const zoneParamName = `zoneValue${index + 1}`;

      switch (checkbox) {
        case 'geo':
          paramString = `${paramName}=count-by-geo`;
          break;
        case 'account':
          paramString = `${paramName}=count-by-account&${zoneParamName}=all`;
          break;
        case 'growth':
          paramString = `${paramName}=zones-growth-over-time&${zoneParamName}=all`;
          break;
        case 'implementation':
          paramString = `${paramName}=display-category-results`;
          break;
        case 'coverage':
          paramString = `${paramName}=zones-coverage-over-time&zones=all`;
          break;
        case 'all':
          paramString = [
            'count-by-geo',
            'count-by-account&zoneValue2=all',
            'zones-growth-over-time&zoneValue3=all',
            'display-category-results',
            'zones-coverage-over-time&zoneValue5=all',
          ]
            .map((value, i) => `value${i + 1}=${value}`)
            .join('&');
          break;
        default:
          return '';
      }
      return paramString;
    });

    return `${baseUrl}?${params.join('&')}`;
  };

  return { createExportUrl };
};

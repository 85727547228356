import React, { useEffect, useState, useRef } from 'react';
import Lottie from 'lottie-react';
import CardRetailer from '../../../components/CardRetailer';
import CardCountry from '../../../components/CardCountry';
import Loading from '../../../assets/animations/spinner_loading.json';
import ErrorIcon from '../../../assets/images/icons/error_Icon.svg';
import NoResultsIcon from '../../../assets/images/icons/noresults_icon.svg';

// Descripción: Este panel muestra los resultados de la busqueda o filtros.

const SearchResults = ({
  data,
  isError,
  filters,
  country,
  fullView,
  isLoading,
  isSuccess,
  handleClick,
  setFullView,
  setShowFilter,
  filtersActive,
}) => {
  const [limitResults, setLimitResults] = useState(8);

  const panel = useRef(null);
  const handleShowMore = () => {
    setLimitResults(limitResults + 4);
  };

  useEffect(() => {
    // Cada ves que hay una busqueda nueva se reinicia
    setLimitResults(8);
  }, [isSuccess]);

  useEffect(() => {
    // Hacemos scroll hacia abajo en el panel
    const element = panel?.current;

    if (element) {
      element.scrollTo(0, element.scrollHeight - element.clientHeight);
    }
  }, [limitResults]);

  return (
    <>
      {!fullView && (
        <>
          <div
            className={`searchresults ${
              !fullView || filtersActive ? 'reveal' : ''
            }`}
          >
            {isLoading && (
              <>
                <h3>Loading...</h3>

                <div className='searchresults__message'>
                  <Lottie animationData={Loading} loop />
                </div>
              </>
            )}

            {isSuccess && (
              <>
                {/* Si hay match en la busqueda para renderizar el country primero */}
                {filters.keyword &&
                (data?.implementations.length || data?.countries.length) ? (
                  <>
                    <h3>
                      Implementations{' '}
                      <span>({data?.implementations_count} Results)</span>
                    </h3>

                    {/* Resultados de busquedas */}
                    <div className='searchresults__results' ref={panel}>
                      {/* Lista completa de resultados */}

                      {data.countries.map((item) => (
                        <CardCountry
                          item={item}
                          country={country}
                          key={item.id + item.name}
                          onClick={() => {
                            setShowFilter('menu');
                            handleClick(item, 'country');
                            setFullView(null);
                          }}
                        />
                      ))}

                      {data.implementations
                        .slice(0, limitResults)
                        .map((item) => (
                          <CardRetailer
                            item={item}
                            country={country}
                            image={item?.images[0]?.name}
                            key={item.id + item.name}
                            onClick={() => {
                              handleClick(item, 'retailer');
                              setFullView(true);
                            }}
                          />
                        ))}
                    </div>

                    {/* Boton para ver mas */}
                    {limitResults <= data.implementations.length ? (
                      <button
                        className='searchresults__showmore'
                        onClick={handleShowMore}
                      >
                        See more
                      </button>
                    ) : null}
                  </>
                ) : null}

                {/* Valores iniciales */}
                {!filters.keyword &&
                (data?.implementations.length || data?.countries.length) ? (
                  <>
                    <h3>
                      Implementations{' '}
                      <span>({data?.implementations_count} Results)</span>
                    </h3>

                    {/* Resultados de busquedas */}
                    <div className='searchresults__results' ref={panel}>
                      {/* Lista completa de resultados */}

                      {[...data.implementations, ...data.countries]
                        .slice(0, limitResults)
                        .map((item) => {
                          return item.retailer ? (
                            <CardRetailer
                              item={item}
                              country={country}
                              image={item?.images[0]?.name}
                              key={item.id + item.name}
                              onClick={() => {
                                handleClick(item, 'retailer');
                                setFullView(true);
                              }}
                            />
                          ) : (
                            <CardCountry
                              item={item}
                              country={country}
                              key={item.id + item.name}
                              onClick={() => {
                                setShowFilter('menu');
                                handleClick(item, 'country');
                                setFullView(null);
                              }}
                            />
                          );
                        })}
                    </div>

                    {/* Boton para ver mas */}
                    {limitResults <=
                    data.implementations.length + data.countries.length ? (
                      <button
                        className='searchresults__showmore'
                        onClick={handleShowMore}
                      >
                        See more
                      </button>
                    ) : null}
                  </>
                ) : null}

                {/* No hay match en la busqueda */}
                {!data?.implementations.length && !data?.countries.length && (
                  <>
                    {/* No hay resultados */}
                    <div className='searchresults__message'>
                      <img src={NoResultsIcon} alt='' />
                      <p>
                        No results found. <br /> Try reseting the filters or
                        searching by product name or location.
                      </p>
                    </div>
                  </>
                )}
              </>
            )}

            {isError && (
              <div className='searchresults__message'>
                <img src={ErrorIcon} alt='' />
                <p>The page you are looking for is temporarily unavailable.</p>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

SearchResults.defaultProps = {
  country: {},
  fullView: false,
  handleClick: () => null,
  setFullView: () => null,
  setShowFilter: () => null,
  filtersActive: [],
  filteredItems: [],
};

export default SearchResults;

import React, { useEffect, useState } from 'react';
import ReactSelect from 'react-select';
import { useAnalyticsByAccount } from '../hooks/useAnalytics';
import DetailsbyAccount from '../components/Tables/DetailsbyAccount';
import LineGraphics from '../components/Graphics/LineGraphics';
import PieGraphics from '../components/Graphics/PieGraphics';
import BarGraphics from '../components/Graphics/BarGraphics';
import Paginate from '../components/Paginate';
import { OverviewGeo } from '../components/Graphics/AnalyticView/OverviewGeo';
import Colors from '../assets/scss/variables/colors.module.scss';
import CheckboxLabel from '../components/Inputs/CheckboxLabel';
import ExportData from '../components/Buttons/ExportData';

// Descripción: Esta pagina se encarga de renderizar todo referente a la vista de analiticas

const AnalyticalView = () => {
  const [geoOptions, setGeoOptions] = useState([]);
  const [zoneOptions, setZoneOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [accountOptions, setAccountOptions] = useState([]);
  const [statesOptions, setStatesOptions] = useState([]);
  const [geoSelecteds, setGeoSelecteds] = useState([]);
  const [countrySelecteds, setCountrySelecteds] = useState([]);
  const [accountSelecteds, setAccountSelecteds] = useState([]);
  const [statesSelecteds, setStatesSelecteds] = useState([]);
  const [isUnisonChecked, setIsUnisonChecked] = useState(false);
  const [isTopAccountChecked, setIsTopAccountChecked] = useState(false);
  const [isStateDisabled, setIsStateDisabled] = useState(false);

  const { data, isLoading, isSuccess, isError, filters, setFilters } =
    useAnalyticsByAccount();

  useEffect(() => {
    const selectedGeoValues = geoSelecteds.map((item) => item.value).join();
    const selectedCountryValues = countrySelecteds
      .map((item) => item.value)
      .join();
    const selectedAccountValues = accountSelecteds
      .map((item) => item.value)
      .join();
    const selectedStatesValues = statesSelecteds
      .map((item) => item.value)
      .join();

    setFilters({
      ...filters,
      geo: selectedGeoValues,
      country: selectedCountryValues,
      account: selectedAccountValues,
      states: selectedStatesValues,
      unison: isUnisonChecked ? '1' : '',
      topAccount: isTopAccountChecked ? '1' : '',
    });
  }, [
    geoSelecteds,
    countrySelecteds,
    accountSelecteds,
    statesSelecteds,
    isUnisonChecked,
    isTopAccountChecked,
  ]);

  useEffect(() => {
    if (!isSuccess) {
      if (isError) throw new Error('No data');
      return;
    }

    const newZoneOptions = [{ value: 'all', label: 'All zones' }];
    const newGeoOptions = [{ value: 'all', label: 'All geos' }];
    const newCountryOptions = [{ value: 'all', label: 'All countries' }];
    const newAccOptions = [{ value: 'all', label: 'All accounts' }];
    const newStatesOptions = [{ value: 'all', label: 'All states' }];

    data.info?.options.zone.forEach((item) => {
      if (
        !newZoneOptions.find((option) => option.value === item.toLowerCase())
      ) {
        const label = item.charAt(0).toUpperCase() + item.slice(1);
        newZoneOptions.push({ value: item.toLowerCase(), label });
      }
    });
    setZoneOptions(newZoneOptions);

    data.info?.options.geo.forEach((item) => {
      newGeoOptions.push({ value: item.toLowerCase(), label: item });
    });
    setGeoOptions(newGeoOptions);

    data.info?.options.country.forEach((item) => {
      newCountryOptions.push({ value: item.toLowerCase(), label: item });
    });
    setCountryOptions(newCountryOptions);

    data.info?.options.account.forEach((item) => {
      newAccOptions.push({ value: item.toLowerCase(), label: item });
    });
    setAccountOptions(newAccOptions);

    data.info?.options.states.forEach((item) => {
      newStatesOptions.push({ value: item.toLowerCase(), label: item });
    });
    setStatesOptions(newStatesOptions);
  }, [isSuccess, isLoading, data, filters]);

  useEffect(() => {
    const selectedGeoValues = geoSelecteds.map((item) => item.value).join();
    const selectedCountryValues = countrySelecteds
      .map((item) => item.value)
      .join();
    const selectedAccountValues = accountSelecteds
      .map((item) => item.value)
      .join();
    const selectedStatesValues = statesSelecteds
      .map((item) => item.value)
      .join();

    setFilters({
      ...filters,
      geo: selectedGeoValues,
      country: selectedCountryValues,
      account: selectedAccountValues,
      states: selectedStatesValues,
      unison: isUnisonChecked ? '1' : '0',
      topAccount: isTopAccountChecked ? '1' : '0',
      page: '1', // Resetting page number to 1
    });
  }, [
    geoSelecteds,
    countrySelecteds,
    accountSelecteds,
    statesSelecteds,
    isUnisonChecked,
    isTopAccountChecked,
  ]);

  // Deshabilita el filtro de State si 'USA' y otro valor están seleccionados
  useEffect(() => {
    const usaSelected = geoSelecteds.some((item) => item.value === 'usa');
    const otherSelected = geoSelecteds.length > 1;
    setIsStateDisabled(usaSelected && otherSelected);
  }, [geoSelecteds]);

  // Renders
  return (
    <section className='analytics'>
      <h1>Analytical view</h1>
      {/* button export data */}
      <ExportData />
      <div className='analytics__table'>
        <h2>Overview by GEO</h2>
        <OverviewGeo />
      </div>

      <div className='analytics__table'>
        <h2>Details by Account</h2>
        <div className='analytics__filters'>
          <ReactSelect
            placeholder='Evo/Gaming/Ultra'
            isSearchable={false}
            options={zoneOptions}
            isLoading={isLoading}
            isDisabled={!isSuccess}
            loadingMessage='Loading...'
            className='analytics__selects'
            classNamePrefix='analytics__selects'
            onChange={(e) => {
              setFilters({ ...filters, zone: e.value });
              setGeoSelecteds([]);
              setCountrySelecteds([]);
              setStatesSelecteds([]);
              setAccountSelecteds([]);
            }}
          />

          <ReactSelect
            styles={{
              valueContainer: (base) => ({
                ...base,
                overflowX: 'scroll',
                flexWrap: 'unset',
              }),
              multiValue: (base) => ({
                ...base,
                flex: '1 0 auto',
              }),
            }}
            isMulti
            placeholder='GEO'
            options={geoOptions}
            value={geoSelecteds}
            isLoading={isLoading}
            isDisabled={!isSuccess}
            loadingMessage='Loading...'
            className='analytics__selects'
            classNamePrefix='analytics__selects'
            onChange={(e) => {
              setGeoSelecteds(e);
              setCountrySelecteds([]);
              setStatesSelecteds([]);
              setAccountSelecteds([]);
            }}
          />

          <ReactSelect
            isMulti
            styles={{
              valueContainer: (base) => ({
                ...base,
                overflowX: 'scroll',
                flexWrap: 'unset',
              }),
              multiValue: (base) => ({
                ...base,
                flex: '1 0 auto',
              }),
            }}
            placeholder='Country'
            isLoading={isLoading}
            isDisabled={!isSuccess}
            options={countryOptions}
            value={countrySelecteds}
            loadingMessage='Loading...'
            className='analytics__selects'
            classNamePrefix='analytics__selects'
            onChange={(e) => {
              setCountrySelecteds(e);
              setStatesSelecteds([]);
              setAccountSelecteds([]);
            }}
          />

          <ReactSelect
            isMulti
            styles={{
              valueContainer: (base) => ({
                ...base,
                overflowX: 'scroll',
                flexWrap: 'unset',
              }),
              multiValue: (base) => ({
                ...base,
                flex: '1 0 auto',
              }),
              container: (base) => ({
                ...base,
                pointerEvents: isStateDisabled ? 'none' : 'auto',
                cursor: isStateDisabled ? 'not-allowed' : 'auto',
                opacity: isStateDisabled ? 0.5 : 1,
              }),
            }}
            placeholder='State'
            isLoading={isLoading}
            isDisabled={!isSuccess || isStateDisabled}
            options={statesOptions}
            value={statesSelecteds}
            loadingMessage='Loading...'
            className='analytics__selects'
            classNamePrefix='analytics__selects'
            onChange={(e) => {
              setStatesSelecteds(e);
              setAccountSelecteds([]);
            }}
          />

          <ReactSelect
            isMulti
            styles={{
              valueContainer: (base) => ({
                ...base,
                overflowX: 'scroll',
                flexWrap: 'unset',
              }),
              multiValue: (base) => ({
                ...base,
                flex: '1 0 auto',
              }),
            }}
            placeholder='Account'
            isLoading={isLoading}
            isDisabled={!isSuccess}
            options={accountOptions}
            value={accountSelecteds}
            loadingMessage='Loading...'
            className='analytics__selects'
            classNamePrefix='analytics__selects'
            onChange={(e) => setAccountSelecteds(e)}
          />
          <div className='analytics__checkboxes'>
            <CheckboxLabel
              labelText='Unison'
              id='unison'
              checked={isUnisonChecked}
              onChange={() => setIsUnisonChecked(!isUnisonChecked)}
              className='checkbox-label checkbox-label__border'
            />
            <CheckboxLabel
              labelText='Top account'
              id='top-account'
              checked={isTopAccountChecked}
              onChange={() => setIsTopAccountChecked(!isTopAccountChecked)}
              className='checkbox-label checkbox-label__border'
            />
          </div>
        </div>
        <DetailsbyAccount
          data={data}
          isError={isError}
          isLoading={isLoading}
          isSuccess={isSuccess}
        />
        <div className='analytics__paginate'>
          <Paginate
            info={data?.info}
            filters={filters}
            setFilters={setFilters}
            items={data?.info?.totalResults}
            itemsPerPage={data?.info?.resultsPerPage}
          />
        </div>
      </div>

      <div className='analytics__grid'>
        <LineGraphics
          geo={filters.geo}
          zone={filters.zone}
          states={filters.states}
          country={filters.country}
          account={filters.account}
          filtersLoading={isLoading}
        />

        <PieGraphics
          geo={filters.geo}
          zone={filters.zone}
          states={filters.states}
          country={filters.country}
          account={filters.account}
          unison={isUnisonChecked ? '1' : isTopAccountChecked ? '0' : ''}
          topAccount={isTopAccountChecked ? '1' : isUnisonChecked ? '0' : ''}
        />

        <BarGraphics
          colors={Colors}
          geo={filters.geo}
          zone={filters.zone}
          states={filters.states}
          country={filters.country}
          account={filters.account}
          filtersLoading={isLoading}
        />
      </div>
    </section>
  );
};

AnalyticalView.propTypes = {};

AnalyticalView.defaultProps = {};

export default AnalyticalView;

import React from 'react';
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';

import Layout from '../layouts/Layout';
import Home from '../containers/Home';
import AnalyticalView from '../containers/AnalyticalView';
import ErrorMessage from '../containers/ErrorMessage';

const Routes = () => {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path='/' element={<Layout />}>
        <Route index element={<Home />} errorElement={<ErrorMessage />} />
        <Route
          path='analytics'
          element={<AnalyticalView />}
          errorElement={<ErrorMessage />}
        />
      </Route>
    )
  );

  return <RouterProvider router={router} />;
};

export default Routes;

import React, { useEffect, useState } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import Lottie from 'lottie-react';

import { useZonesGrowthOverTime } from '../../hooks/useAnalytics';
import Graphicscontainers from './Graphicscontainers';
import SeparatorNumber from '../../helpers/SeparatorNumber';
import TooltipChartModals from '../Modals/TooltipChartLines';
import LottieLoader from '../../assets/animations/cyber_loader.json';
import LottieError from '../../assets/animations/error_lottie.json';
import Colors from '../../assets/scss/variables/colors.module.scss';

// Descripción: Este chart muestra un grafico lineal

const LineGraphics = ({
  geo,
  zone,
  country,
  states,
  account,
  filtersLoading,
}) => {
  const [chartData, setChartData] = useState([]);
  const { data, isLoading, isSuccess, isError, filters, setFilters } =
    useZonesGrowthOverTime();

  const { colorSecundario, colorVariante, colorClaro, colorVarianteNueve } =
    Colors;

  useEffect(() => {
    setFilters({
      ...filters,
      geo,
      zone,
      states,
      country,
      account,
    });
  }, [geo, zone, country, states, account]);

  useEffect(() => {
    if (!filtersLoading && isSuccess && data) {
      // Conertimos los valores en un array

      if (data?.data?.chronologically) {
        const arrayOfValues = Object.values(data?.data?.chronologically);

        // Organizamos el array por Q
        const sortArray = arrayOfValues.sort((a, b) => {
          const valueA = a.order.toUpperCase();
          const valueB = b.order.toUpperCase();

          if (valueA < valueB) {
            return -1;
          }
          if (valueA > valueB) {
            return 1;
          }

          // names must be equal
          return 0;
        });

        // Lo seteamos
        setChartData(sortArray);
      }
    }
  }, [isSuccess, data, filtersLoading]);

  const indicators = [
    { title: 'Intel® Evo™ Zones', color: Colors.colorSecundario },
    { title: 'Gaming Zones', color: Colors.colorVarianteTrece },
    { title: 'Intel® Core™ Ultra Zones', color: Colors.colorVarianteNueve },
  ];
  // Renders
  return (
    <>
      <Graphicscontainers
        hasFilters
        data={data}
        filters={filters}
        isSuccess={isSuccess}
        isLoading={isLoading}
        footerAlign='flex-end'
        setFilters={setFilters}
        headerTitle='Zones Growth Over Time'
        indicators={
          data && (data.lenght === null || undefined) ? '' : indicators
        }
      >
        <>
          {isLoading && (
            <Lottie
              style={{ width: '10%', margin: '0 auto' }}
              animationData={LottieLoader}
            />
          )}

          {data && (data.lenght === null || undefined) && (
            <Lottie
              style={{ width: '10%', margin: '0 auto' }}
              animationData={LottieError}
            />
          )}

          {isSuccess && (
            <ResponsiveContainer width='90%' height='90%'>
              <LineChart
                height={120}
                data={chartData}
                margin={{
                  top: 5,
                  right: 5,
                  left: -20,
                  bottom: 10,
                }}
              >
                <CartesianGrid horizontal={false} stroke={colorClaro} />

                <YAxis
                  type='number'
                  fontSize={12}
                  allowDecimals
                  stroke={colorClaro}
                  tickFormatter={SeparatorNumber}
                />

                <XAxis
                  name='period'
                  dataKey='period'
                  fontSize={12}
                  stroke={colorClaro}
                  tickMargin={11}
                />

                <Tooltip
                  offset={-70}
                  content={<TooltipChartModals />}
                  allowEscapeViewBox={{ x: true, y: true }}
                />

                <Line
                  type='monotone'
                  dataKey='evo'
                  stroke={colorSecundario}
                  strokeWidth={2}
                />

                <Line
                  dataKey='gaming'
                  strokeWidth={2}
                  type='monotone'
                  stroke={colorVariante}
                />

                <Line
                  dataKey='ultra'
                  strokeWidth={2}
                  type='monotone'
                  stroke={colorVarianteNueve}
                />
              </LineChart>
            </ResponsiveContainer>
          )}

          {isError && (
            <Lottie
              style={{ width: '10%', margin: '0 auto' }}
              animationData={LottieError}
            />
          )}
        </>
      </Graphicscontainers>
    </>
  );
};

LineGraphics.propTypes = {};

LineGraphics.defaultProps = {};

export default LineGraphics;

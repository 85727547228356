import React from 'react';
import { AiOutlineCopyright } from 'react-icons/ai';

const Footer = () => {
  return (
    <>
      <footer className='footer'>
        <small>
          <AiOutlineCopyright /> 2023 Intel Corporation.
        </small>
      </footer>
    </>
  );
};

export default Footer;

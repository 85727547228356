import { useState } from 'react';
import { useQuery } from 'react-query';
import api from '../services/api.service';
import { CHECKBOX_KEY } from '../constants/api.type';
import DeleteEmptyKeys from '../helpers/DeleteEmptyKeys';

// Descripcion del hook: Este hook se encarga de hacer el llamado a la api

const useSearch = () => {
  const [filters, setFilters] = useState({
    keyword: null,
    zone: null,
    region: null,
    country: null,
    retailer: null,
    index: null,
  });

  const { data, isLoading, isSuccess, isError } = useQuery(
    [CHECKBOX_KEY, JSON.stringify(filters)],
    async () => {
      const filteredFilters = DeleteEmptyKeys(filters, true);
      return api.search.getSearch(filteredFilters);
    },
    {
      cacheTime: 0, // Configuración para evitar que se guarde en caché
    }
  );

  return { data, isLoading, isSuccess, isError, filters, setFilters };
};

export default useSearch;

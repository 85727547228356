const SortArray = (array, value) => {
  return array.sort((a, b) => {
    if (value === '') {
      return a.id - b.id;
    }

    if (value === 'name') {
      return a.name.localeCompare(b.name);
    }

    if (value === null) {
      return a.id - b.id;
    }

    return false;
  });
};

export default SortArray;

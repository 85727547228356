import React, { useState } from 'react';
import CheckboxLabel from '../Inputs/CheckboxLabel';
import { useExportUrl } from '../../hooks/useAnalytics';

const ModalExportData = ({ handleClose, setIsModalOpen }) => {
  const [checkboxesSelected, setCheckboxesSelected] = useState({
    all: false,
    geo: false,
    account: false,
    growth: false,
    implementation: false,
    coverage: false,
  });
  const handleCheckboxChange = (id) => {
    setCheckboxesSelected((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };
  const getSelectedCheckboxes = () => {
    return Object.entries(checkboxesSelected)
      .filter(([, value]) => value === true)
      .map(([key]) => key);
  };

  const { createExportUrl } = useExportUrl();
  const handleExport = async () => {
    const selectedCheckboxes = getSelectedCheckboxes();
    if (selectedCheckboxes.length === 0) return;

    const exportUrl = createExportUrl(selectedCheckboxes);

    try {
      const response = await fetch(exportUrl);
      const data = await response.json();

      if (data.status === 'success') {
        window.open(data.file_url, '_blank');
        setIsModalOpen(false);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('Error exporting data:', error);
    }
  };

  return (
    <div className='export-data-modal'>
      <div className='export-data-modal__content'>
        <p className='export-data-modal__text'>
          Select as many sections as you want to export
        </p>
        <CheckboxLabel
          labelText='All'
          id='all'
          checked={checkboxesSelected.all}
          onChange={() => handleCheckboxChange('all')}
          className='checkbox-label checkbox-label__export'
        />

        <CheckboxLabel
          labelText='Overview by GEO'
          id='geo'
          checked={checkboxesSelected.geo}
          onChange={() => handleCheckboxChange('geo')}
          className='checkbox-label checkbox-label__export'
        />

        <CheckboxLabel
          labelText='Details by Account'
          id='account'
          checked={checkboxesSelected.account}
          onChange={() => handleCheckboxChange('account')}
          className='checkbox-label checkbox-label__export '
        />

        <CheckboxLabel
          labelText='Zones Growth Over Time'
          id='growth'
          checked={checkboxesSelected.growth}
          onChange={() => handleCheckboxChange('growth')}
          className='checkbox-label checkbox-label__export'
        />

        <CheckboxLabel
          labelText='Zones Implementation Type'
          id='implementation'
          checked={checkboxesSelected.implementation}
          onChange={() => handleCheckboxChange('implementation')}
          className='checkbox-label checkbox-label__export'
        />

        <CheckboxLabel
          labelText='Zones Coverage Over Time'
          id='coverage'
          checked={checkboxesSelected.coverage}
          onChange={() => handleCheckboxChange('coverage')}
          className='checkbox-label checkbox-label__export'
        />
        <div className='export-data-modal__footer'>
          <button
            className='export-data-modal__close-button'
            onClick={handleClose}
          >
            Cancel
          </button>
          <button className='export-data__button' onClick={handleExport}>
            Export Data
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModalExportData;

import {
  SEARCH_API,
  GRAPHICS_API,
  COUNTRIES_API,
  ANALYTICS_BY_GEO_API,
  ANALYTICS_BY_ACCOUNT_API,
  ANALYTICS_ZONES_COVERAGE_API,
  ANALYTICS_ZONES_GROWTH_OVER_TIME_API,
  ANALYTICS_ZONES_COVERAGE_OVER_TIME_API,
  ANALYTICS_ZONES_IMPLEMENTATION_API,
  EXPORT_BASE_URL_API,
} from '../constants/api.type';

import DeleteEmptyKeys from '../helpers/DeleteEmptyKeys';

async function callApi(endpoint, options = {}) {
  const optionsCopy = {
    ...options,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      ...options.headers,
    },
  };

  const url = endpoint;

  const response = await fetch(url, optionsCopy);
  const data = await response.json();

  if (!response.ok) throw new Error(data?.name || 'Internal error');

  return data;
}

const api = {
  countries: {
    getAll() {
      return callApi(COUNTRIES_API, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      });
    },
  },

  retailers: {
    getAll() {
      // return callApi(RETAILERS_API, ``, {
      //   method: 'GET',
      //   headers: {
      //     'Content-Type': 'application/json',
      //     Accept: 'application/json',
      //   },
      // });
    },

    getRetailersByCountry(filters) {
      const url = new URL(SEARCH_API);
      const params = new URLSearchParams(filters);

      return callApi(`${url}&${params.toString()}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      });
    },
  },

  search: {
    getSearch(filters) {
      const url = new URL(SEARCH_API);
      const params = new URLSearchParams(filters);

      // Append the search parameters to the URL
      url.search = params;

      return callApi(url.toString(), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      });
    },
  },

  graphics: {
    getAll(filters) {
      const url = new URL(GRAPHICS_API);
      const params = new URLSearchParams(filters);

      return callApi(`${url}&${params.toString()}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      });
    },

    getPerCountry(filters) {
      DeleteEmptyKeys(filters);

      const url = new URL(GRAPHICS_API);
      const params = new URLSearchParams(filters);

      return callApi(`${url}&${params.toString()}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      });
    },
  },

  analytics: {
    getByGeo() {
      return callApi(ANALYTICS_BY_GEO_API, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      });
    },

    getByAccount(filters) {
      const url = new URL(ANALYTICS_BY_ACCOUNT_API);
      const params = new URLSearchParams(filters);

      return callApi(`${url}&${params.toString().replace('%2C', ',')}`);
    },

    getZonesGrowthOverTime(filters) {
      DeleteEmptyKeys(filters);

      const url = new URL(ANALYTICS_ZONES_GROWTH_OVER_TIME_API);
      const params = new URLSearchParams(filters);

      return callApi(`${url}&${params.toString().replace('%2C', ',')}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      });
    },

    getZonesCoverage(filters) {
      const url = new URL(ANALYTICS_ZONES_COVERAGE_API);
      const params = new URLSearchParams(filters);

      return callApi(`${url}&${params.toString().replace('%2C', ',')}`);
    },

    getZonesCoverageOverTime(filters) {
      const url = new URL(ANALYTICS_ZONES_COVERAGE_OVER_TIME_API);
      const params = new URLSearchParams(filters);

      return callApi(`${url}&${params.toString().replace('%2C', ',')}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      });
    },
    getZonesImplementation(filters) {
      const url = new URL(ANALYTICS_ZONES_IMPLEMENTATION_API);
      const params = new URLSearchParams(filters);

      return callApi(`${url}&${params.toString()}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      });
    },
    getExportFiles() {
      return callApi(EXPORT_BASE_URL_API, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      });
    },
  },
};

export default api;

import React from 'react';
import Maps from '../components/Maps/Maps';

const Home = () => {
  return (
    <main className='main'>
      <h2>Intel® Display Zones</h2>
      <Maps />
    </main>
  );
};

export default Home;

import React, { useState, useContext } from 'react';
import CountrySelectedContext from '../../../contexts/CountrySelectedContext';
import useSearch from '../../../hooks/useSearch';
import useCountries from '../../../hooks/useCountries';
import SearchInput from './SearchInput';
import SearchFilters from './SearchFilters';
import SearchResults from './SearchResults';

const SearchPanels = ({ fullView, setFullView, setShowFilter }) => {
  const [filtersActive, setFiltersActive] = useState(false);
  const { country, setCountry } = useContext(CountrySelectedContext);
  const countries = useCountries();

  const { data, isLoading, isSuccess, isError, filters, setFilters } =
    useSearch();
  // Funciones
  const handleClick = (item, type) => {
    if (type === 'retailer') {
      const findCountry = countries.data.find(
        (country) => country.id === item.pin
      );
      setCountry({ ...findCountry, retailer: { ...item } });
    }

    if (type === 'country') {
      // const findCountry = countries.data.find(
      //   (country) => country.id === item.pin
      // );
      setCountry({ ...item });
    }
  };

  return (
    <>
      <div className='searchpanels'>
        <h3>Search</h3>

        <SearchInput filters={filters} setFilters={setFilters} />

        <SearchFilters
          data={data}
          filters={filters}
          isSuccess={isSuccess}
          isLoading={isLoading}
          setFilters={setFilters}
          setFiltersActive={setFiltersActive}
        />
      </div>

      <SearchResults
        data={data}
        isError={isError}
        filters={filters}
        country={country}
        fullView={fullView}
        isLoading={isLoading}
        isSuccess={isSuccess}
        handleClick={handleClick}
        setFullView={setFullView}
        setShowFilter={setShowFilter}
        filtersActive={filtersActive}
      />
    </>
  );
};

SearchPanels.propTypes = {};

SearchPanels.defaultProps = {};

export default SearchPanels;

import { useQuery } from 'react-query';
import api from '../services/api.service';
import { COUNTRIES_KEY } from '../constants/api.type';

// Descripcion del hook: Este hook se encarga de hacer el llamado a la api

const useCountries = () => {
  const countries = useQuery([COUNTRIES_KEY], async () =>
    api.countries.getAll()
  );

  return countries;
};

export default useCountries;

import React from 'react';
import Proptypes from 'prop-types';
import ArrowIcon from '../../assets/images/icons/arrow__icon.svg';

/**
 * Descripción: Este componente se encarga de abrir un modal, este mostrará los charts correspondientes.
 *
 * Implementación: Se importa justo debajo del modal y recibe un props (type), que se encarga de modificar los estilos del componente.
 */

const ModalButton = ({ type, onClick }) => {
  return (
    <button
      title='Graphic Trends'
      className={`modalbutton ${type}`}
      onClick={onClick}
    >
      Graphic Trends
      <img src={ArrowIcon} alt='Graphic Trends' />{' '}
    </button>
  );
};

ModalButton.prototypes = {
  // Cambia de color y estilado el boton
  type: Proptypes.string.isRequired,
};

export default ModalButton;

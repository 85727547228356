import React, { createContext, useState, useEffect } from 'react';
import GLOBE_RADIUS from '../constants/model.type';
import CalcPosFromLatLonRad from '../helpers/CalcPosFromLatLondAndRad.helper';

/**
 * Descripcion del context: Este contexto nos almacena y transforma la informacion de el hostpot o item seleccionado para hacer la animacion del planeta y mostrar la data necesaria.
 *
 * Implementacion: Se importa en el canva y debe recibir los items del value
 */

const CountrySelectedContext = createContext(null);

export const CountrySelectedProvider = ({ children }) => {
  const [country, setCountry] = useState(null);
  const [countryFocus, setCountryFocus] = useState(null);
  const [savePrevCountry, setSavePrevCountry] = useState({
    wasClicked: false,
    coord: null,
  });

  useEffect(() => {
    if (country !== null && country !== 0) {
      const { latlons } = country;
      const latLon = latlons[Math.floor(Math.random() * latlons.length)];
      const coordinates = CalcPosFromLatLonRad(
        latLon[0],
        latLon[1],
        GLOBE_RADIUS
      );

      setCountryFocus({
        x: coordinates[0],
        y: coordinates[1],
        z: coordinates[2],
      });
      setSavePrevCountry({
        wasClicked: true,
        coord: { x: coordinates[0], y: coordinates[1], z: coordinates[2] },
      });
    }

    if (country === 0) {
      // console.log('Se elimina');
      setCountryFocus(null);
    }

    if (country === null) {
      // console.log('Se reinicia');
      setCountryFocus(null);
      setSavePrevCountry({
        wasClicked: false,
        coord: { x: 0, y: 0, z: 10 },
      });
    }
  }, [country]);

  return (
    <CountrySelectedContext.Provider
      value={{
        country,
        setCountry,
        countryFocus,
        savePrevCountry,
        setCountryFocus,
        setSavePrevCountry,
      }}
    >
      {children}
    </CountrySelectedContext.Provider>
  );
};

export default CountrySelectedContext;

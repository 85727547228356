import React from 'react';
import PropTypes from 'prop-types';
import CounterNumbers from '../CounterNumbers';

// Descripción: Este componente renderiza los numeros al abrir la info de un pais

const ModalsCardsInfo = ({ number, label, color }) => {
  return (
    <div>
      <div className='modalcountry__spark' />

      <h2>
        <CounterNumbers
          delay={0.2}
          width={20}
          height={30}
          color={color}
          numbers={String(number)}
        />
      </h2>
      <span>{label}</span>
    </div>
  );
};

ModalsCardsInfo.propTypes = {
  label: PropTypes.string,
  number: PropTypes.string,
};

ModalsCardsInfo.defaultProps = {
  label: '',
  number: '0',
};

export default ModalsCardsInfo;

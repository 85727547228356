import React from 'react';
import { useLocation } from 'wouter';
import { Link } from 'react-router-dom';
import ArrowIcon from '../../../assets/images/icons/asidearrow__icon.svg';
import SearchIcon from '../../../assets/images/icons/search__icon.svg';
import MenuIcon from '../../../assets/images/icons/menu__icon.svg';
import SeeAllIcon from '../../../assets/images/icons/seeall__icon.svg';
import EvoIcon from '../../../assets/images/icons/evo__icon.svg';
import GamingIcon from '../../../assets/images/icons/gaming__icon.svg';
import DisplayIcon from '../../../assets/images/icons/display__icon.png';
import AnalyticsIcon from '../../../assets/images/icons/icon_analytics.png';
import HelpIcon from '../../../assets/images/icons/help__icon.svg';
import UnisonIcon from '../../../assets/images/icons/icon__unison.svg';
import UltraIcon from '../../../assets/images/icons/ultra__icon.svg';

// Descripción: Listado de acciones y paneles para el aside.

const AsideActions = ({
  zones,
  showFilter,
  expandAside,
  handleClick,
  setFullView,
  setShowFilter,
  handleFilters,
  setExpandAside,
  setShowHelpScreen,
}) => {
  const [location] = useLocation();

  return (
    <>
      <div className='aside__actions'>
        <button
          className='aside__actions__btn'
          onClick={() => setExpandAside(!expandAside)}
        >
          <img src={ArrowIcon} alt='Expandible Icon' />
        </button>

        {location !== '/' ? (
          <>
            <Link to='/' className='aside__actions__btn' data-tab='false'>
              <img src={DisplayIcon} alt='Icon Search' />

              <p>Display Zones View</p>
            </Link>

            <hr />

            <Link
              to='/analytics'
              className='aside__actions__btn'
              data-tab='true'
            >
              <img src={AnalyticsIcon} alt='Icon Search' />

              <p>Analytical View</p>
            </Link>
          </>
        ) : (
          <>
            <button
              className='aside__actions__btn'
              data-tab={showFilter === 'home' ? 'true' : 'false'}
              onClick={() => handleClick('home')}
            >
              <img src={MenuIcon} alt='Icon Search' />
              <p>Home</p>
            </button>

            <button
              className='aside__actions__btn'
              data-tab={showFilter === 'search' ? 'true' : 'false'}
              onClick={() => handleClick('search')}
            >
              <img src={SearchIcon} alt='Icon Search' />

              <p>Search</p>
            </button>

            <hr />

            {/* Filtros */}
            <button
              className='aside__actions__btn'
              data-tab={showFilter === 'all' ? 'true' : 'false'}
              // data-filter={zones === 'all' ? 'true' : 'false'}
              onClick={() => handleFilters('all')}
            >
              <img src={SeeAllIcon} alt='Icon All' />

              <p>See all</p>
            </button>

            <button
              className='aside__actions__btn'
              onClick={() => handleFilters('evo')}
              data-tab={zones === 'evo' ? 'true' : 'false'}
            >
              <img src={EvoIcon} alt='Icon Evo' />

              <p>Intel® Evo™ Zones</p>
            </button>

            <button
              className='aside__actions__btn'
              data-tab={zones === 'gaming' ? 'true' : 'false'}
              onClick={() => handleFilters('gaming')}
            >
              <img src={GamingIcon} alt='Icon Gaming' />

              <p>Gaming Zones</p>
            </button>

            <button
              className='aside__actions__btn'
              data-tab={zones === 'unison riser' ? 'true' : 'false'}
              onClick={() => handleFilters('unison riser')}
            >
              <img src={UnisonIcon} alt='Icon Unison Riser' />

              <p>Intel® Unison™</p>
            </button>

            <button
              className='aside__actions__btn'
              data-tab={zones === 'ultra' ? 'true' : 'false'}
              onClick={() => handleFilters('ultra')}
            >
              <img src={UltraIcon} alt='Icon Ultra' />
              <p>Intel® Core™ Ultra Zones</p>
            </button>

            <hr />

            <Link
              to='/analytics'
              className='aside__actions__btn'
              data-tab='false'
            >
              <img src={AnalyticsIcon} alt='Icon Search' />

              <p>Analytical View</p>
            </Link>

            <button
              className='aside__actions__help'
              onClick={() => {
                setFullView(false);
                setShowFilter('menu');
                setShowHelpScreen(false);
              }}
            >
              <img src={HelpIcon} alt='Icon Search' />
            </button>
          </>
        )}
      </div>
    </>
  );
};

export default AsideActions;

import React from 'react';
import Img from 'react-cool-img';
import { PATH_IMAGES } from '../constants/api.type';
import ErrorImage from '../assets/images/static/ErrorImage.jpg';
import Placeholder from '../assets/images/static/Placeholder.gif';

/**
 * Descripción: Como la card de resultados se repite en toda la interfaz este componente soluciona este problema
 *
 * Implementación: Se importa y se le pasan los props necesarios
 */

const CardRetailer = ({ item, country, image, onClick }) => {
  return (
    <button
      className='cardretailers'
      onClick={onClick}
      data-latlons={item.latlons}
      title='Click to open retailer'
      // style={{ backgroundImage: `url(${PATH_IMAGES + image})` }}
      data-active={item?.id === country?.id ? 'true' : 'false'}
    >
      <Img
        cache
        error={ErrorImage}
        src={PATH_IMAGES + image}
        placeholder={Placeholder}
        alt='REACT COOL IMG'
      />

      <p>
        {item.name}, <br />
        <span>{item.retailer}</span>
      </p>
    </button>
  );
};

CardRetailer.defaultProps = {
  item: {
    id: 0,
    name: 'Example',
    city: 'City Example',
  },
  country: {},
  image: Placeholder,
  onClick: () => null,
};

export default CardRetailer;

import React, { useEffect } from 'react';
import { useGoogleMap } from '@react-google-maps/api';

/**
 * Descripción: Este componente se usa asi en base a la doc, su unica funciona es hacer zoom y centrar el marcador clickeado
 *
 * Implementación: Se le pasa el valor del contexto country <Panning country={country} />
 */

const Panning = ({ country }) => {
  const map = useGoogleMap();

  useEffect(() => {
    if (map && country) {
      map.panTo({ lat: country.latlons[0][0], lng: country.latlons[0][1] });
      map.setZoom(4);
    }

    if (map && !country) {
      map.setZoom(3);
    }
  }, [country]);

  return <></>;
};

Panning.propTypes = {};

Panning.defaultProps = {};

export default Panning;

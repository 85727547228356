import React from 'react';
import erroricon from '../assets/images/icons/error__icon.svg';

export default function ErrorMessage() {
  const refreshPage = () => {
    window.location.reload();
  };

  return (
    <div className='errormsg'>
      <div className='errormsg__box'>
        <div className='errormsg__box__text'>
          {/* Icon */}
          <img width={59} height={59} src={erroricon} alt='' />
          {/* Text */}
          <p>An unexpected error has occurred. Please try again.</p>
        </div>

        <button className='errormsg__box__button' onClick={refreshPage}>
          Ok
        </button>
      </div>
    </div>
  );
}

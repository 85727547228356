import React, { useEffect, useState } from 'react';
import Accordion from '../../../components/Accordion';
import Checkboxs from './Checkboxs';
import SortArray from '../../../helpers/SortArray';

const SearchFilters = ({
  data,
  filters,
  isSuccess,
  isLoading,
  setFiltersActive,
  setFilters,
}) => {
  const zones = [
    {
      name: 'evo',
      label: 'Intel® Evo™ zones',
      value: 'evo',
    },
    {
      name: 'gaming',
      label: 'Gaming Zones',
      value: 'gaming',
    },
    {
      name: 'unison riser',
      label: 'Intel® Unison™',
      value: 'unison riser',
    },
    {
      name: 'ultra',
      label: 'Intel® Core™ Ultra Zones',
      value: 'ultra',
    },
  ];

  const [regions, setRegions] = useState([]);
  const [countries, setCountries] = useState([]);
  const [retailersF, setretailersFF] = useState([]);
  const [zonesSelected, setZonesSelected] = useState([]);
  const [regionsSelected, setRegionsSelected] = useState([]);
  const [countriesSelected, setCountriesSelected] = useState([]);
  const [retailersFSelected, setretailersFFSelected] = useState([]);

  const handleCheckbox = (e, type) => {
    const { value } = e.target;

    // Si se clickea algun checkbox de zonas
    if (type === 'zones') {
      // Si se toca el boton de ALL, selecciona todos los checkbox
      if (zones.length === zonesSelected.length) {
        setZonesSelected([]);
      } else {
        setZonesSelected(zones.map((filter) => filter.value));
      }
      // Eliminamos o agregamos el valor nuevo, dependiendo del caso
      if (zonesSelected.includes(value)) {
        const filterIndex = zonesSelected.indexOf(value);
        const newFilter = [...zonesSelected];
        newFilter.splice(filterIndex, 1);
        setZonesSelected(newFilter);
      } else {
        setZonesSelected([...zonesSelected, value]);
      }

      setRegionsSelected([]);
      setCountriesSelected([]);
      setretailersFFSelected([]);
    }
    // Si se clickea algun checkbox de regiones
    if (type === 'regions') {
      if (regions.length === regionsSelected.length) {
        setRegionsSelected([]);
      } else {
        setRegionsSelected(regions.map((filter) => filter.value));
      }

      // Eliminamos o agregamos el valor nuevo, dependiendo del caso
      if (regionsSelected.includes(value)) {
        const filterIndex = regionsSelected.indexOf(value);
        const newFilter = [...regionsSelected];
        newFilter.splice(filterIndex, 1);
        setRegionsSelected(newFilter);
      } else {
        setRegionsSelected([...regionsSelected, value]);
      }

      setCountriesSelected([]);
      setretailersFFSelected([]);
    }

    // Si se clickea algun checkbox de paises
    if (type === 'countries') {
      if (countries.length === countriesSelected.length) {
        setCountriesSelected([]);
      } else {
        setCountriesSelected(countries.map((filter) => filter.value));
      }

      // Eliminamos o agregamos el valor nuevo, dependiendo del caso
      if (countriesSelected.includes(value)) {
        const filterIndex = countriesSelected.indexOf(value);
        const newFilter = [...countriesSelected];
        newFilter.splice(filterIndex, 1);
        setCountriesSelected(newFilter);
      } else {
        setCountriesSelected([...countriesSelected, value]);
      }

      setretailersFF([]);
      setretailersFFSelected([]);
    }

    if (type === 'retailersF') {
      if (retailersF.length === retailersFSelected.length) {
        setretailersFFSelected([]);
      } else {
        setretailersFFSelected(retailersF.map((filter) => filter.value));
      }

      // Eliminamos o agregamos el valor nuevo, dependiendo del caso
      if (retailersFSelected.includes(value)) {
        const filterIndex = retailersFSelected.indexOf(value);
        const newFilter = [...retailersFSelected];
        newFilter.splice(filterIndex, 1);
        setretailersFFSelected(newFilter);
      } else {
        setretailersFFSelected([...retailersFSelected, value]);
      }
    }
  };

  // Limpiamos los filtros
  const handleReset = (e) => {
    e.preventDefault();

    // Tuve que hacer esto porque el register nunca funcionó bien al 100%.
    const checkboxes = document.querySelectorAll(
      'input[type="checkbox"]:checked'
    );
    // eslint-disable-next-line no-param-reassign
    checkboxes.forEach((el) => (el.checked = false));
    setZonesSelected([]);
    setRegionsSelected([]);
    setCountriesSelected([]);
    setretailersFFSelected([]);
  };

  useEffect(() => {
    const newRegions = [];
    const newCountries = [];
    const newRetailersF = [];

    if (data && isSuccess) {
      /* Este forEach asigna los valores a ciertos tabs dependiendo de los datos filtrados */
      data.implementations.forEach((element) => {
        // Regiones
        if (element.region && !regionsSelected.length) {
          if (!newRegions.find((r) => r.name === element.region)) {
            newRegions.push({
              label: element.region,
              name: element.region,
              value: element.region,
            });

            SortArray(newRegions, 'name');
            setRegions(newRegions);
          }
        }

        // Pais
        if (element.country && !countriesSelected.length) {
          if (!newCountries.find((c) => c.name === element.country)) {
            newCountries.push({
              label: element.country,
              name: element.country,
              value: element.country,
            });

            SortArray(newCountries, 'name');
            setCountries(newCountries);
          }
        }

        // Retailer
        if (
          element.retailer &&
          regionsSelected.length &&
          countriesSelected.length &&
          !retailersFSelected.length
        ) {
          if (!newRetailersF.find((r) => r.name === element.retailer)) {
            newRetailersF.push({
              label: element.retailer,
              name: element.retailer,
              value: element.retailer,
            });

            SortArray(newRetailersF, 'name');
            setretailersFF(newRetailersF);
          }
        }
      });

      // Si no hay datos, limpiamos los tabs
      if (!data.implementations.length) {
        setRegions([]);
        setCountries([]);
        setretailersFF([]);
      }
    }
  }, [isSuccess]);

  useEffect(() => {
    // Enviamos los checksbox marcados
    setFilters({
      ...filters,
      zone: zonesSelected,
      region: regionsSelected,
      country: countriesSelected,
      retailer: retailersFSelected,
    });

    if (zonesSelected.length) {
      setFiltersActive(true);
    } else {
      setFiltersActive(false);
    }
  }, [zonesSelected, regionsSelected, countriesSelected, retailersFSelected]);

  return (
    <div className='filters'>
      <div className='filters__header'>
        <h3>Filters</h3>

        <button type='reset' onClick={handleReset}>
          Reset
        </button>
      </div>

      {/* Experience Zone  */}
      <Accordion defaultOpen columns={1} title='Experience Zone'>
        <Checkboxs
          label='See all'
          name='all'
          value='all'
          checked={zonesSelected.includes('all')}
          onChange={(e) => handleCheckbox(e, 'zones')}
          disabled={isLoading}
        />

        {zones.map((item, index) => (
          <Checkboxs
            key={index}
            label={item.label}
            name={item.name}
            value={item.value}
            checked={zonesSelected.includes(item.value)}
            onChange={(e) => handleCheckbox(e, 'zones')}
            disabled={isLoading}
          />
        ))}
      </Accordion>

      {/* Region  */}
      {zonesSelected.length && regions.length ? (
        <Accordion defaultOpen columns={3} title='Region'>
          {regions.map((item, index) => (
            <Checkboxs
              key={index}
              label={item.label}
              name={item.name}
              value={item.value}
              checked={regionsSelected.includes(item.value)}
              onChange={(e) => handleCheckbox(e, 'regions')}
              disabled={isLoading}
            />
          ))}
        </Accordion>
      ) : null}

      {/* Country  */}
      {zonesSelected.length && regionsSelected.length && countries ? (
        <Accordion defaultOpen columns={2} title='Country'>
          {countries.map((item, index) => (
            <Checkboxs
              key={index}
              label={item.label}
              name={item.name}
              value={item.value}
              checked={countriesSelected.includes(item.value)}
              onChange={(e) => handleCheckbox(e, 'countries')}
              disabled={isLoading}
            />
          ))}
        </Accordion>
      ) : null}

      {/* retailersF  */}
      {zonesSelected.length &&
      regionsSelected.length &&
      countriesSelected.length &&
      retailersF ? (
        <Accordion defaultOpen columns={2} title='Retailers'>
          {retailersF.map((item, index) => (
            <Checkboxs
              key={index}
              label={item.label}
              name={item.name}
              value={item.value}
              checked={retailersFSelected.includes(item.value)}
              onChange={(e) => handleCheckbox(e, 'retailersF')}
              disabled={isLoading}
            />
          ))}
        </Accordion>
      ) : null}
    </div>
  );
};

export default SearchFilters;

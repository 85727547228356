import React, { useEffect, useState } from 'react';
import CloseIcon from '../assets/images/icons/close__icon.svg';

// Descripción: Este componente se encarga de abrir en un nuevo modal la imagen a full view para lograr ver con mas detalle la imagen

const ImageViewer = ({ imageToOpen, setImageToOpen }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => {
    setIsOpen(true);
    setImageToOpen('');
  };

  useEffect(() => {
    imageToOpen !== '' ? setIsOpen(true) : setIsOpen(false);
  }, [imageToOpen]);

  if (!isOpen) {
    return null;
  }

  return (
    <div className='viewer'>
      <button className='retailer__close' onClick={handleClose}>
        <img src={CloseIcon} alt='Close Icon' />
      </button>

      <div className='viewer__container'>
        <img src={imageToOpen} alt='Image of retailer' />
      </div>
    </div>
  );
};

export default ImageViewer;

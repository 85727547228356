import React from 'react';
import SearchPanels from './SearchPanel';
import ModalImplementations from '../../../components/Modals/ModalImplementations';
import ModalCountry from '../../../components/Modals/ModalCountry';

//  Descripción: Panel que se usa como plantilla para plasmar el contenido dependiento del tab

const AsideContainers = ({
  zones,
  show,
  country,
  setShow,
  setZones,
  fullView,
  showFilter,
  setCountry,
  setFullView,
  countryFocus,
  setShowFilter,
  setGraphicsScreen,
}) => {
  return (
    <>
      <div className='aside__container'>
        {showFilter === 'search' && (
          <SearchPanels
            country={country}
            fullView={fullView}
            setZones={setZones}
            setCountry={setCountry}
            setFullView={setFullView}
            setShowFilter={setShowFilter}
          />
        )}

        {/* Titulo del aside */}
        {showFilter !== 'home' && showFilter !== 'search' && (
          <div className='aside__header'>
            {zones === 'all' && <h3>Intel® Display Zones</h3>}

            {zones === 'evo' && <h3>Intel® Evo™ Zones</h3>}

            {zones === 'gaming' && <h3>Gaming Zones</h3>}

            {zones === 'unison riser' && <h3>Intel® Unison™</h3>}

            {zones === 'ultra' && <h3>Intel® Core™ Ultra Zones</h3>}

            {!countryFocus ? (
              <p>
                Explore the implementations made by region, product segment,
                country, or retailer and see the impact generated in key
                accounts around the world.
              </p>
            ) : null}

            {/* Cuadros con la info */}
            {!countryFocus ? (
              <ModalImplementations
                show={show}
                zones={zones}
                country={country}
                setShow={setShow}
                setGraphicsScreen={setGraphicsScreen}
              />
            ) : null}

            {countryFocus && !fullView ? (
              <ModalCountry
                show={show}
                zones={zones}
                setShow={setShow}
                country={country}
                setFullView={setFullView}
                setGraphicsScreen={setGraphicsScreen}
              />
            ) : null}
          </div>
        )}
      </div>
    </>
  );
};

export default AsideContainers;

import React, { useContext, useState, useEffect } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import FiltersZones from '../../../contexts/FiltersZonesContext';
import SearchIcon from '../../../assets/images/icons/search__icon__input.svg';

/**
 * Descripción: Este componente se encarga de recibir y filtrar la informacion del usuario.
 *
 * Implementación: solo funciona para esta necesidad.
 */

const SearchInput = ({ filters, setFilters }) => {
  const { setZones } = useContext(FiltersZones);
  const [userQuery, setUserquery] = useState(filters.keyword || '');

  const onSubmit = () => {
    if (userQuery === '') {
      setFilters({ ...filters, keyword: null });
    } else {
      setFilters({ ...filters, keyword: userQuery.toLowerCase() });
    }
  };

  const pressEnter = (e) => {
    if (e.keyCode === 13 || e.keyCode === '13') {
      onSubmit();
    }
  };

  const handleChange = (e) => {
    e.target.value !== 'evo' &&
      e.target.value !== 'gaming' &&
      e.target.value !== 'unison riser' &&
      setUserquery(e.target.value);
  };

  const handleFocus = () => {
    setZones('all');
  };

  const handleClear = () => {
    setUserquery('');
    setFilters({ ...filters, keyword: null });
  };

  useEffect(() => {
    document.addEventListener('keypress', pressEnter);

    return () => {
      document.removeEventListener('keydown', pressEnter);
    };
  }, [userQuery]);

  return (
    <>
      <div className='searchpanels__input'>
        <div className='searchpanels__input__square' />
        <input
          type='text'
          name='search'
          value={userQuery}
          autoCorrect='off'
          onFocus={handleFocus}
          onChange={handleChange}
          autoComplete='new-password'
          placeholder='Search by Country, product or retailer'
        />

        {userQuery && filters.keyword ? (
          <button filters-type='reset' onClick={handleClear}>
            <AiOutlineClose />
          </button>
        ) : null}

        <button filters-type='submit' onClick={onSubmit}>
          <img src={SearchIcon} alt='' />
        </button>
      </div>
    </>
  );
};

export default SearchInput;

import React from 'react';
import {
  ResponsiveContainer,
  BarChart,
  Legend,
  XAxis,
  YAxis,
  Bar,
  LabelList,
} from 'recharts';

import Colors from '../../../assets/scss/variables/colors.module.scss';
// eslint-disable-next-line import/prefer-default-export
export const OtherType = ({ data, renderCustomizedLabel }) => {
  const { colorUnison, colorClaro } = Colors;

  const renderCustomTick = (tickProps) => {
    const { x, y, payload } = tickProps;
    return (
      <svg>
        <g transform={`translate(${x},${y})`}>
          <text
            x={-24}
            y={0}
            dy={4}
            style={{ textAlign: 'right' }}
            fill='#FFFFFF'
            fontSize='11'
          >
            {payload.value}
          </text>
          <path d={`M31,12H${x * 16}`} stroke='#525252' />
        </g>
      </svg>
    );
  };
  const renderCustomizedLegend = (props) => {
    const { payload } = props;
    const customLegend = payload.map((entry) => {
      let displayName = entry.value;
      switch (entry.value) {
        case 'unison riser':
          displayName = 'Intel® Unison™';
          break;
        default:
          break;
      }
      return { ...entry, value: displayName };
    });

    return (
      <>
        <div
          className=''
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '10px',
            alignItems: 'center',
            justifyContent: 'start',
          }}
        >
          {customLegend.map((entry, index) => {
            if (
              entry.value === 'evoGap' ||
              entry.value === 'gamingGap' ||
              entry.value === 'ultraGap'
            ) {
              return;
            }
            return (
              <div
                key={`item-${index}`}
                style={{
                  color: Colors.colorClaro,
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  padding: '10px 0 48px',
                  fontSize: '11px',
                }}
              >
                <div
                  style={{
                    margin: '0 5px',
                    width: '10px',
                    height: '10px',
                    backgroundColor: entry.color,
                  }}
                />
                <span>{entry.value}</span>
              </div>
            );
          })}
        </div>
        <div>
          <p
            style={{
              position: 'absolute',
              top: '40px',
              left: '5px',
              color: Colors.colorClaro,
            }}
          >
            Other Type
          </p>
        </div>
      </>
    );
  };

  return (
    <>
      {data && (
        <>
          <ResponsiveContainer width='70%' height={238}>
            <BarChart
              width={100}
              height={100}
              data={data}
              margin={{ top: 10, right: 12, left: 7, bottom: 12 }}
              layout='vertical'
              barSize={5}
              barCategoryGap={10}
              barGap={10}
            >
              {/* <Tooltip /> */}
              <Legend
                content={renderCustomizedLegend}
                verticalAlign='top'
                align='left'
              />
              <XAxis hide type='number' />
              <YAxis
                type='category'
                dataKey='region'
                stroke={colorClaro}
                fontSize='11'
                tickLine={0}
                axisLine={0}
                tickMargin={25}
                tick={renderCustomTick}
                interval={0}
              />
              <Bar dataKey='unison riser' stackId='a' fill={colorUnison}>
                <LabelList
                  dataKey='unison riser'
                  position='right'
                  content={renderCustomizedLabel}
                />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </>
      )}
    </>
  );
};

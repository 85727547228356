import React from 'react';
import PlaceIcon from '../assets/images/icons/place__icon.svg';
import ErrorImage from '../assets/images/static/ErrorImage.jpg';

/**
 * Descripción: Como la card de resultados se repite en toda la interfaz este componente soluciona este problema
 *
 * Implementación: Se importa y se le pasan los props necesarios
 */

const CardCountry = ({ item, country, onClick }) => {
  return (
    <>
      <button
        key={item.id}
        data-latlons={item.latlons}
        data-active={item?.id === country?.id ? 'true' : 'false'}
        className='searchresults__country'
        title='Click to see marker on map'
        onClick={onClick}
      >
        <img src={PlaceIcon} alt='' />
        <p>{item.name}</p>
      </button>
    </>
  );
};

CardCountry.defaultProps = {
  item: {
    id: 0,
    name: 'Example',
    city: 'City Example',
  },
  country: {},
  image: ErrorImage,
  onClick: () => null,
};

export default CardCountry;

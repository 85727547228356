import React, { useEffect, useState } from 'react';
import {
  Bar,
  XAxis,
  YAxis,
  Legend,
  ResponsiveContainer,
  BarChart,
  LabelList,
  Label,
} from 'recharts';
import Lottie from 'lottie-react';
import { useZonesCoverageOverTime } from '../../hooks/useAnalytics';
import Graphicscontainers from './Graphicscontainers';
import LottieLoader from '../../assets/animations/cyber_loader.json';
import LottieError from '../../assets/animations/error_lottie.json';

// Descripción: Este chart mustra un grifico de barras horizontal
const BarGraphics = ({
  colors,
  geo,
  zone,
  states,
  country,
  account,
  filtersLoading,
}) => {
  const [chartData, setChartData] = useState([]);
  const [labelPosition, setLabelPosition] = useState('');

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  // Hooks

  const { data, isLoading, isSuccess, isError, filters, setFilters } =
    useZonesCoverageOverTime();
  const dataKeys = [
    { key: 'evo', color: colors.colorSecundario },
    { key: 'ultra', color: colors.colorVarianteNueve },
  ];

  const getGap = (screenWidth, total) => {
    const barsCount = dataKeys.filter(({ key }) =>
      chartData.some((data) => data[key])
    ).length;

    const gapPercentage = 0.1;
    let gap;

    if (barsCount === 1) {
      // Gaps proporcionales para un solo conjunto de barras
      if (total < 10) gap = 0.05;
      else if (total <= 20) gap = 0.8;
      else if (total < 300) gap = total * gapPercentage;
      else if (total < 400) gap = 15;
      else gap = Math.max(10, 50);

      // Ajustes adicionales basados en el ancho de pantalla
      if (screenWidth <= 950) gap = Math.max(gap, 50);
      else if (screenWidth <= 1200) gap = Math.max(gap, 100);
      else gap = Math.max(20, Math.min(90, (total / 500) * 20));

      return gap;
    }

    if (chartData.length <= 2) {
      gap = total * gapPercentage;
      return gap;
    }
    if (chartData.length <= 4) {
      if (total <= 5) gap = 1.2;
      else if (total <= 10) gap = 1.5;
      else if (total <= 20) gap = 1.8;
      else if (total < 30) gap = 2;
      else if (total < 40) gap = 2.3;
      else gap = total * gapPercentage;

      return gap;
    }

    if (total <= 5) gap = 1 * total;
    else if (total <= 10) gap = 0.4 * total;
    else if (total <= 15) gap = 0.09 * total;
    else if (total <= 20) gap = 0.2 * total;
    else if (total <= 30) gap = 0.55 * total;
    else if (total <= 50) gap = 0.18 * total;
    else if (total <= 100) gap = 0.06 * total;
    else if (total <= 150) gap = 0.13 * total;
    else if (total <= 199) gap = 0.25 * total;
    else if (total <= 260) gap = 0.05 * total;
    else if (total <= 299) gap = 0.25 * total;
    else if (total <= 400) gap = 0.051 * total;
    else if (total <= 500) gap = 0.052 * total;
    else if (total <= 599) gap = 0.053 * total;
    else if (total <= 650) gap = 0.054 * total;
    else if (total <= 700) gap = 0.055 * total;
    else if (total <= 1099) gap = 0.05 * total;
    else if (total <= 1400) gap = 0.05 * total;
    else if (screenWidth <= 950) gap = 130;
    else if (screenWidth <= 1200) gap = 120;
    else gap = Math.max(20, Math.min(90, (total / 500) * 20));

    return gap;
  };

  // UseEffects

  useEffect(() => {
    const selectedZone = zone === 'gaming' ? 'all' : zone;
    setFilters({
      ...filters,
      geo,
      zone: selectedZone,
      states,
      country,
      account,
    });
  }, [geo, zone, country, states, account]);

  useEffect(() => {
    if (!filtersLoading && isSuccess && data) {
      // Conertimos los valores en un array

      if (data?.data?.chronologically) {
        const arrayOfValues = Object.values(data?.data?.chronologically);

        // Organizamos el array por Q
        const sortArray = arrayOfValues.sort((a, b) => {
          const valueA = a.order.toUpperCase();
          const valueB = b.order.toUpperCase();

          if (valueA < valueB) {
            return -1;
          }
          if (valueA > valueB) {
            return 1;
          }

          // names must be equal
          return 0;
        });

        const sortArrayWithGap = sortArray.map((entry) => ({
          ...entry,
          gap: getGap(screenWidth, sortArray),
        }));
        // Lo seteamos
        setChartData(sortArrayWithGap);
      }
    }
  }, [isSuccess, data, filtersLoading]);

  const renderCustomTick = (tickProps) => {
    const { x, y, payload } = tickProps;
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={-24}
          y={0}
          dy={4}
          style={{ textAlign: 'right' }}
          fill={colors.colorClaro}
          fontSize='11'
        >
          {payload.value}
        </text>
        <path d={`M31,10H${x * 37}`} stroke={colors.colorVarianteOnce} />
      </g>
    );
  };
  const renderCustomizedLegend = (props) => {
    const { payload } = props;
    const customLegend = payload.map((entry) => {
      let displayName = entry.value;
      switch (entry.value) {
        case 'evo':
          displayName = 'Intel® Evo™ Zones';
          break;
        case 'ultra':
          displayName = 'Intel® Core™ Ultra Zones';
          break;
        default:
          break;
      }
      return { ...entry, value: displayName };
    });

    return (
      <>
        <div
          className=''
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '10px',
            alignItems: 'center',
            justifyContent: 'start',
            position: 'absolute',
            top: -95,
            left: 0,
          }}
        >
          {customLegend.map((entry, index) => {
            if (entry.value === 'evoGap' || entry.value === 'ultraGap') {
              return;
            }
            return (
              <div
                key={`item-${index}`}
                style={{
                  color: colors.colorClaro,
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  padding: '10px 0 48px',
                  fontSize: '11px',
                  textTransform: 'capitalize',
                }}
              >
                <div
                  style={{
                    margin: '0 5px',
                    width: '10px',
                    height: '10px',
                    backgroundColor: entry.color,
                  }}
                />
                <span>{entry.value}</span>
              </div>
            );
          })}
        </div>
        <div>
          <p
            style={{
              position: 'absolute',
              top: '-30px',
              left: '5px',
              color: colors.colorClaro,
            }}
          >
            Zone Type
          </p>
          <p
            style={{
              position: 'absolute',
              top: '-30px',
              right: '5px',
              color: colors.colorClaro,
            }}
          >
            Total
          </p>
        </div>
      </>
    );
  };
  const renderCustomizedLabel = (props) => {
    const { content, ...rest } = props;
    if (props.value !== 0)
      return <Label {...rest} fontSize='10' fill='#FFFFFF' />;
  };

  const tickFormatter = (value, index) => {
    const total = chartData[index]?.total || '';
    return total;
  };
  const chartDataFormatted = chartData?.map((region) => {
    return {
      ...region,
      evoGap: region.evo > 0 ? getGap(screenWidth, region.evo) : 0,
      ultraGap: region.ultra > 0 ? getGap(screenWidth, region.total) : 0,
    };
  });
  useEffect(() => {
    const barsCount = dataKeys.filter(({ key }) =>
      chartDataFormatted.some((data) => data[key])
    ).length;

    if (barsCount > 1) {
      setLabelPosition('middle');
    } else if (barsCount === 1) {
      setLabelPosition('insideLeft');
    }
    getGap();
  }, [chartDataFormatted]);

  return (
    <>
      <Graphicscontainers
        hasFilters
        data={data}
        size='1/span 2'
        filters={filters}
        isSuccess={isSuccess}
        isLoading={isLoading}
        footerAlign='flex-end'
        setFilters={setFilters}
        headerTitle='Zones Coverage Over Time'
      >
        <>
          {isLoading && (
            <Lottie
              style={{ width: '10%', margin: '0 auto' }}
              animationData={LottieLoader}
            />
          )}

          {data && data.error && (
            <Lottie
              style={{
                width: '5%',
              }}
              animationData={LottieError}
            />
          )}

          {isSuccess && data.data !== null && (
            <ResponsiveContainer
              width='98%'
              height='100%'
              style={{ position: 'relative' }}
            >
              <BarChart
                layout='vertical'
                width={100}
                height={100}
                data={chartDataFormatted}
                margin={{
                  top: 20,
                  right: 30,
                  left: 10,
                  bottom: 10,
                }}
                barSize={5}
                style={{ position: 'relative' }}
              >
                <Legend
                  content={renderCustomizedLegend}
                  verticalAlign='top'
                  align='left'
                />
                <YAxis
                  width={60.5}
                  fontSize='11'
                  name='period'
                  type='category'
                  dataKey='period'
                  stroke={colors.colorClaro}
                  tickLine={0}
                  axisLine={0}
                  tickMargin={25}
                  tick={renderCustomTick}
                />
                <YAxis
                  type='category'
                  orientation='right'
                  tickFormatter={tickFormatter}
                  stroke={colors.colorClaro}
                  fontSize='11'
                  tickLine={0}
                  axisLine={0}
                  tickMargin={25}
                  yAxisId='total'
                  width={60}
                />
                <XAxis hide type='number' />
                <Bar dataKey='evo' stackId='a' fill={colors.colorSecundario} />
                <Bar dataKey='evoGap' stackId='a' fill='transparent'>
                  <LabelList
                    dataKey='evo'
                    position={labelPosition}
                    content={renderCustomizedLabel}
                  />
                </Bar>
                <Bar
                  dataKey='ultra'
                  stackId='a'
                  fill={colors.colorVarianteNueve}
                />
                <Bar dataKey='ultraGap' stackId='a' fill='transparent'>
                  <LabelList
                    dataKey='ultra'
                    position='insideLeft'
                    content={renderCustomizedLabel}
                  />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          )}

          {isError && (
            <Lottie
              style={{ width: '10%', margin: '0 auto' }}
              animationData={LottieError}
            />
          )}
        </>
      </Graphicscontainers>
    </>
  );
};

BarGraphics.propTypes = {};

BarGraphics.defaultProps = {};

export default BarGraphics;

import React from 'react';

/**
 * Descripción: Este boton se reutiliza y cambia de color dependiendo de que se ha seleccionado.
 *
 * Implementación: se importa y por props se le pasan los elementos requeridos.
 */

const CallToActions = ({
  label,
  onClick,
  imgSrc,
  showImpressionGraphic,
  zones,
}) => {
  return (
    <>
      <button
        className={`calltoaction ${zones}`}
        onClick={onClick}
        data-impressions={showImpressionGraphic}
      >
        {label} {imgSrc && <img src={imgSrc} alt='' />}
      </button>
    </>
  );
};

CallToActions.prototype = {};

export default CallToActions;

import React, { useEffect, useState, useContext } from 'react';
import Lottie from 'lottie-react';
import PropTypes from 'prop-types';
import GraphicsContext from '../../contexts/GraphicsContext';
import { useGraphicsPerCountry } from '../../hooks/useGraphics';
import FiltersCharts from '../../components/FiltersCharts';
import ChartStores from '../../components/Modals/ChartStores';
import ChartImpressions from '../../components/Modals/ChartImpressions';
import CallToActions from '../../components/Buttons/CallToActions';
import ArrowIcon from '../../assets/images/icons/arrow__icon.svg';
import CloseIcon from '../../assets/images/icons/close__icon.svg';
import LottieLoader from '../../assets/animations/cyber_loader.json';
import LottieError from '../../assets/animations/error_lottie.json';

/**
 * Descripción: Este container se encarga de mostrar el chart
 *
 * Implementación: Es un contenedor, debe de recibir la zona y el pais
 */

const ChartsScreen = ({ zones, country, setShow, setGraphicsScreen }) => {
  const [showImpressionGraphic, setShowImpressionGraphic] = useState(false);
  const { countryInfo } = useContext(GraphicsContext);
  const { data, isLoading, isSuccess, isError, filters, setFilters } =
    useGraphicsPerCountry();

  // Funciones
  // console.log('data', data);
  // console.log(zones);

  const handleClose = () => {
    setShow(true);
    setGraphicsScreen(false);
  };

  const handleClick = () => {
    setShowImpressionGraphic(!showImpressionGraphic);
  };

  useEffect(() => {
    if (country) {
      setFilters({ ...filters, country: country.name });
    }

    return () => {
      setShowImpressionGraphic(false);
    };
  }, []);

  // Renders
  return (
    <>
      <div className='chartscreen'>
        <button className='chartscreen__close' onClick={handleClose}>
          <img src={CloseIcon} alt='Close Icon' />
        </button>

        <div className='chartscreen__container'>
          <div
            className={`chartscreen__title ${zones}`}
            data-impressions={showImpressionGraphic}
          >
            <h1>
              {!showImpressionGraphic
                ? 'Total Implementations'
                : 'Daily Impressions'}
            </h1>
          </div>

          <div className={`chartscreen__header ${zones}`}>
            <div className='chartscreen__header__title'>
              <h2>
                {zones === 'all'
                  ? 'See All'
                  : zones === 'evo'
                  ? 'Intel® Evo™ Zones'
                  : zones === 'gaming'
                  ? 'Gaming Zones'
                  : zones === 'unison riser'
                  ? 'Intel® Unison™'
                  : 'Intel® Core™ Ultra Zones'}{' '}
                {country?.name && `| ${country?.name}`}
              </h2>
            </div>

            <div className='chartscreen__header__filters'>
              <FiltersCharts
                data={data?.data}
                filters={filters}
                isLoading={isLoading}
                isSuccess={isSuccess}
                setFilters={setFilters}
              />
            </div>
          </div>

          <div className='chartscreen__chart'>
            {isLoading && (
              <Lottie
                style={{ width: '10%', margin: '0 auto' }}
                animationData={LottieLoader}
              />
            )}

            {isSuccess && (
              <>
                {!showImpressionGraphic ? (
                  <ChartStores
                    chart={data.data}
                    country={countryInfo.country}
                    zone={countryInfo.zone}
                  />
                ) : (
                  <ChartImpressions
                    chart={data.data}
                    country={countryInfo.country}
                    zone={countryInfo.zone}
                  />
                )}
              </>
            )}
          </div>

          <div className='chartscreen__indicators'>
            {zones === 'all' && (
              <p>
                <span className='spark evo' /> Intel® Evo™ Zones
              </p>
            )}

            {zones === 'all' && (
              <p>
                <span className='spark gaming' /> Gaming Zones
              </p>
            )}

            {/* {zones === 'all' && (
              <p>
                <span className='spark riser' /> Intel® Unison™
              </p>
            )} */}

            {zones === 'all' && (
              <p>
                <span className='spark ultra' /> Intel® Core™ Ultra Zones
              </p>
            )}
          </div>

          <div className='chartscreen__footer'>
            <CallToActions
              label={
                showImpressionGraphic
                  ? 'See Total Implementations'
                  : 'See Daily Impressions'
              }
              imgSrc={ArrowIcon}
              onClick={handleClick}
              showImpressionGraphic={showImpressionGraphic}
              zones={zones}
            />
          </div>
        </div>

        {isError && (
          <Lottie
            style={{ width: '10%', margin: '0 auto' }}
            animationData={LottieError}
          />
        )}
      </div>
    </>
  );
};

ChartsScreen.propTypes = {
  /** Filtro activo */
  zones: PropTypes.string,

  /**  Objeto con toda la info del pais */
  country: PropTypes.object,
};

ChartsScreen.defaultProps = {
  zones: 'all',
  country: {},
};

export default ChartsScreen;

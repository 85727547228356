import React from 'react';
import {
  ResponsiveContainer,
  BarChart,
  Legend,
  XAxis,
  YAxis,
  Bar,
  LabelList,
} from 'recharts';
import Lottie from 'lottie-react';
import Colors from '../../../assets/scss/variables/colors.module.scss';
import LottieError from '../../../assets/animations/error_lottie.json';

// eslint-disable-next-line import/prefer-default-export
export const ZoneType = ({
  renderCustomizedLegend,
  dataFormatted,
  renderCustomizedLabel,
}) => {
  const {
    colorSecundario,
    colorVarianteTrece,
    colorVarianteNueve,
    colorClaro,
    colorVarianteOnce,
  } = Colors;

  const renderCustomTick = (tickProps) => {
    const { x, y, payload } = tickProps;
    return (
      <svg>
        <g transform={`translate(${x},${y})`}>
          <text
            x={-24}
            y={0}
            dy={4}
            style={{ textAlign: 'right' }}
            fill={colorClaro}
            fontSize='11'
          >
            {payload.value}
          </text>
          <path d={`M31,10H${x * 24}`} stroke={colorVarianteOnce} />
        </g>
      </svg>
    );
  };

  const tickFormatter = (value, index) => {
    const total = dataFormatted[index]?.total || '';
    return total;
  };

  const colors = {
    evo: colorSecundario,
    gaming: colorVarianteTrece,
    ultra: colorVarianteNueve,
  };

  return (
    <>
      {(dataFormatted.length === null || undefined) && (
        <Lottie
          style={{ width: '10%', margin: '0 auto' }}
          animationData={LottieError}
        />
      )}
      {dataFormatted && (
        <ResponsiveContainer style={{ position: 'relative' }} height={240}>
          <BarChart
            width={100}
            height={100}
            data={dataFormatted}
            margin={{ top: 10, right: 35, left: 12, bottom: 10 }}
            layout='vertical'
            barSize={5}
          >
            <Legend
              content={renderCustomizedLegend}
              verticalAlign='top'
              align='left'
            />
            <XAxis hide type='number' />
            <YAxis
              type='category'
              dataKey='region'
              stroke={colorClaro}
              fontSize='11'
              tickLine={0}
              axisLine={0}
              tickMargin={25}
              tick={renderCustomTick}
              orientation='left'
              interval={0}
            />
            <YAxis
              type='category'
              orientation='right'
              tickFormatter={tickFormatter}
              stroke={colorClaro}
              fontSize='11'
              tickLine={0}
              axisLine={0}
              tickMargin={65}
              yAxisId='total'
              width={60}
            />
            <Bar dataKey='evo' stackId='a' fill={colors.evo} />
            <Bar dataKey='evoGap' stackId='a' fill='transparent'>
              <LabelList
                dataKey='evo'
                position='middle'
                content={renderCustomizedLabel}
              />
            </Bar>
            <Bar dataKey='gaming' stackId='a' fill={colors.gaming} />
            <Bar dataKey='gamingGap' stackId='a' fill='transparent'>
              <LabelList
                dataKey='gaming'
                position='middle'
                content={renderCustomizedLabel}
              />
            </Bar>
            <Bar dataKey='ultra' stackId='a' fill={colors.ultra} />
            <Bar dataKey='ultraGap' stackId='a' fill='transparent'>
              <LabelList
                dataKey='ultra'
                position='insideLeft'
                content={renderCustomizedLabel}
              />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      )}
    </>
  );
};

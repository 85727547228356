export const COUNTRIES_KEY = 'countries';
export const RETAILERS_KEY = 'retailers';
export const GRAPHICS_KEY = 'graphics';
export const SEARCH_KEY = 'search';
export const CHECKBOX_KEY = 'filters_accordion';
export const ANALYTICS_KEY = 'analytics';
export const ANALYTICS_GEO_KEY = 'ANALYTICS_BY_GEO_API';
export const ANALYTICS_ACCOUNT_KEY = 'ANALYTICS_BY_ACCOUNT_API';
export const ANALYTICS_BAR_KEY = 'ANALYTICS_ZONES_GROWTH_OVER_TIME_API';
export const ANALYTICS_ZONES_COVERAGE_OVER_TIME_KEY =
  'ANALYTICS_ZONES_COVERAGE_OVER_TIME_API';
export const ANALYTICS_ZONES_IMPLEMENTATION_KEY =
  'ANALYTICS_ZONES_IMPLEMENTATION_API';
export const ANALYTICS_PIE_KEY = 'ANALYTICS_ZONES_COVERAGE_API';

export const COUNTRIES_API = process.env.REACT_APP_COUNTRIES_API;
export const RETAILERS_API = process.env.REACT_APP_RETAILERS_API;
export const GRAPHICS_API = process.env.REACT_APP_GRAPHICS_API;
export const SEARCH_API = process.env.REACT_APP_SEARCH_API;
export const EXPORT_BASE_URL_API = process.env.REACT_APP_EXPORT_BASE_URL;

export const ANALYTICS_BY_GEO_API =
  process.env.REACT_APP_ANALYTICS_COUNT_BY_GEO_API;

export const ANALYTICS_ZONES_IMPLEMENTATION_API =
  process.env.REACT_APP_ANALYTICS_ZONES_IMPLEMENTATION_API;

export const ANALYTICS_BY_ACCOUNT_API =
  process.env.REACT_APP_ANALYTICS_COUNT_BY_ACCOUNT_API;

export const ANALYTICS_ZONES_GROWTH_OVER_TIME_API =
  process.env.REACT_APP_ANALYTICS_ZONES_GROWTH_OVER_TIME_API;

export const ANALYTICS_ZONES_COVERAGE_API =
  process.env.REACT_APP_ANALYTICS_ZONES_COVERAGE_API;

export const ANALYTICS_ZONES_COVERAGE_OVER_TIME_API =
  process.env.REACT_APP_ANALYTICS_ZONES_COVERAGE_OVER_TIME_API;

export const PATH_IMAGES = process.env.REACT_APP_IMAGES_BUCKET;
export const PATH_VIDEOS = process.env.REACT_APP_VIDEO_BUCKET;

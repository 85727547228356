import React, { useEffect, useState } from 'react';
import { RiArrowDownSLine } from 'react-icons/ri';

/**
 * Descripción: Accordion para mostrar los filtros.
 *
 * Implementación: Esta pensado solo para usar en los filtros.
 */

const Accordion = ({ title, children, defaultOpen, columns }) => {
  const [hasOpen, setHasOpen] = useState(false);

  const handleClick = () => {
    setHasOpen(!hasOpen);
  };

  useEffect(() => {
    defaultOpen && setHasOpen(true);
  }, [defaultOpen]);

  return (
    <>
      <div className={`accordion ${hasOpen ? 'active' : ''}`}>
        <button className='accordion__header' onClick={handleClick}>
          <p>{title}</p>

          <RiArrowDownSLine />
        </button>

        <div className='accordion__panel' data-columns={columns}>
          {children}
        </div>
      </div>
    </>
  );
};

Accordion.defaultProps = {
  /* Texto del tab */
  title: '',

  /* Array para los checkbos */
  options: [],

  /* Nos dice si el accordion esta desplegado o no */
  defaultOpen: false,
};

export default Accordion;

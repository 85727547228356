import React, { useEffect } from 'react';
import CallToActions from './Buttons/CallToActions';
import HandIcon from '../assets/images/static/helperscreen__hand.png';
import ArrowIcon from '../assets/images/icons/tour__cta__arrow.svg';

/**
 * Descripción: Esta pagina funciona como una ayuda visual al usuario.
 *
 * Implementación: Solo se importa.
 */

const HelpScreen = ({ showHelpScreen, setShowHelpScreen }) => {
  const handleClick = () => {
    setShowHelpScreen(true);
    sessionStorage.setItem('tour', true);
  };

  useEffect(() => {
    const getSavedValue = sessionStorage.getItem('tour');

    if (getSavedValue) {
      setShowHelpScreen(true);
    }
  }, []);

  return (
    <>
      {showHelpScreen !== true && (
        <section className='helpscreen'>
          {/* Ayuda Panel lateral */}
          <div className='helpscreen__panel'>
            <div className='helpscreen__panel__text'>
              <p>
                This section contains information on each zone and
                implementations by country, this box varies depending on which
                zone filter you have applied.
              </p>
            </div>
            <div className='helpscreen__panel__border'>
              <div className='guide' />
            </div>
          </div>

          {/* Ayuda para la busqueda */}
          <div className='helpscreen__search'>
            <div className='helpscreen__search__text'>
              <p>Press to access the search panel.</p>
            </div>
            <div className='helpscreen__search__border'>
              <div className='guide' />
            </div>
          </div>

          {/* Ayuda para los filtros */}
          <div className='helpscreen__filters'>
            <div className='helpscreen__filters__text'>
              <p>
                Press the buttons to filter the markers on the map by type of
                Zones.
              </p>
            </div>

            <div className='helpscreen__filters__border'>
              <div className='guide' />
            </div>
          </div>

          {/* Indicador de scroll */}
          <div className='helpscreen__center'>
            <img
              src={HandIcon}
              alt='Hand icon'
              title='Press and drag to rotate the world'
              loading='lazy'
            />

            <p>
              Navigate by dragging and zooming to discover markers.
              <br />
              Click on each marker to obtain detailed information.
            </p>

            <CallToActions
              zones='all'
              label='Continue'
              imgSrc={ArrowIcon}
              onClick={handleClick}
              title='Press to close the help screen'
            />
          </div>
        </section>
      )}
    </>
  );
};

export default HelpScreen;
